import { ConversationData, Message as TalkJsClientMessage } from 'talkjs/types/talk.types';
import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import type { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { env } from '@bridebook/toolbox/src/env';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { Conversation, Message } from '@bridebook/toolbox/src/inbox/api/talkjs/webhook.types';
import { ChatUserOptions, TalkJsRoles } from './types';

/**
 * Returns a basic chat user object for couple
 */
export const createCoupleChatUserOptions = ({
  profileId,
  partners,
  countryCode,
  locale,
}: {
  profileId: IWedding['id'];
  partners: IWedding['partners'];
  countryCode: CountryCodes;
  locale: string;
}): ChatUserOptions => ({
  id: profileId,
  name: getCoupleNames(partners),
  role: getTalkJsCoupleRole(countryCode),
  locale: getTalkJsLocale(locale),
});

/**
 * Returns a basic chat user object for supplier
 */
export const createSupplierChatUserOptions = (supplier: ISupplier): ChatUserOptions => {
  const countryCode = supplier.address.country;
  const { locale } = gazetteer.getMarketByCountry(countryCode, CountryCodes.GB);
  return {
    id: supplier.id,
    name: supplier.name,
    role: getTalkJsSupplierRole(countryCode),
    locale: getTalkJsLocale(locale),
  };
};

/**
 * Returns a basic chat user object for admin
 */
export const createAdminChatUserOptions = (userId: string): ChatUserOptions => ({
  id: userId,
  name: 'Bridebook',
  role: TalkJsRoles.Admin,
  locale: getTalkJsLocale(gazetteer.getMarketByCountry(CountryCodes.GB).locale),
  photoUrl: `${env.COUPLESIDE_URL}/favicons/icon-192x192.png`,
});

/**
 * Returns the names of the couple to display in the UI
 */
export const getCoupleNames = (partners: string[]) => {
  if (partners.length > 0) {
    return `${partners[0] || 'Partner'} & ${partners[1] || 'Partner'}`;
  }
  return 'The Couple';
};

/**
 * Returns locale supported by TalkJS based on the user's locale
 * @see https://talkjs.com/docs/Features/Multiple_Languages/#currently-available-languages
 */
export const getTalkJsLocale = (locale: string) => {
  // Get the 2-letter language code from the locale
  const languageCode = locale.split('-')[0];

  switch (languageCode) {
    case 'de':
      return 'de-DE';
    case 'fr':
      return 'fr-FR';
    default:
      return 'en-US';
  }
};

/**
 * Returns one of the roles for couples defined in TalkJS Dashboard
 */
export const getTalkJsCoupleRole = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.DE:
      return TalkJsRoles.Couple_DE;
    case CountryCodes.FR:
      return TalkJsRoles.Couple_FR;
    case CountryCodes.IE:
      return TalkJsRoles.Couple_IE;
    case CountryCodes.GB:
      return TalkJsRoles.Couple_UK;
    default:
      return TalkJsRoles.Couple_Global;
  }
};

/**
 * Returns one of the roles for suppliers defined in TalkJS Dashboard
 */
export const getTalkJsSupplierRole = (countryCode: CountryCodes) => {
  switch (countryCode) {
    case CountryCodes.DE:
      return TalkJsRoles.Supplier_DE;
    case CountryCodes.FR:
      return TalkJsRoles.Supplier_FR;
    case CountryCodes.IE:
      return TalkJsRoles.Supplier_IE;
    case CountryCodes.GB:
      return TalkJsRoles.Supplier_UK;
    default:
      return TalkJsRoles.Supplier_Global;
  }
};

export const isCoupleRole = (role?: string) => !!role && role.startsWith('couple');
export const isSupplierRole = (role?: string) => !!role && role.startsWith('supplier');

/**
 * Converts a message received by onMessage callback from TalkJS client
 * to the format used by TalkJS API
 */
export const mapTalkJsClientMessageToAPIMessage = (message: TalkJsClientMessage): Message => ({
  attachment: message.attachment
    ? {
        url: message.attachment.url,
        size: message.attachment.size,
      }
    : null,
  conversationId: message.conversation.id,
  createdAt: message.timestamp,
  custom: message.custom,
  id: message.id,
  location: message.location ? (message.location as Message['location']) : null,
  origin: message.origin as Message['origin'],
  readBy: [],
  senderId: message.senderId || '',
  text: message.body,
  type: 'UserMessage',
});

/**
 * Returns true if conversation has been marked as archived by couple
 */
export const isArchivedByCouple = (conversation: Conversation | ConversationData) =>
  Boolean(conversation.custom?.archivedByCouple);
