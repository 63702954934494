import { differenceInCalendarMonths } from 'date-fns';
import { all, any, flatten, isEmpty, propEq, takeLast, values, whereEq } from 'ramda';
import * as R from 'remeda';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { applyIsoDuration, mapDateToUI, mapToExactDate } from '@bridebook/toolbox/src';
import type { IDatePicker } from '@bridebook/toolbox/src/datepicker/types';
import gazetteer, { CountryCodes, type Market } from '@bridebook/toolbox/src/gazetteer';
import { IDropdownOption } from '@bridebook/ui';
import { IChecklistFilter } from 'lib/checklist/types';
import { format } from 'lib/i18n/utils/get-date-fns-format';
import { getI18n } from '../i18n/getI18n';
import { getSplitTasks } from './Tasks.utils';

// FIXME: temp solution to hide articles tasks for i18n version
const uuidRegEx = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const extractId = (taskId: string) => {
  if (!taskId) return null;
  const rawId = uuidRegEx.exec(taskId);
  return rawId ? rawId[0] : null;
};

/**
 * Extract task name without Link
 */
export const extractRawName = (taskName: string): string => {
  const regexAllTags = /<(.|\n)*?>/g;
  const regexSingleTag = /name='(.*)'/;
  const htmlTags = taskName.match(regexAllTags);
  if (!htmlTags) return taskName;
  let nameRaw = taskName;
  htmlTags.forEach((tag) => {
    const text = regexSingleTag.exec(tag);
    nameRaw = text ? nameRaw.replace(text.input, text[1]) : '';
  });
  return nameRaw;
};

export const getCompleteSubtasks = (subtasks: Array<any>) =>
  subtasks.filter((t) => t.done === true);

// if year is undecided take current year + 2
export const getPeriodName = ({
  date,
  period,
  market,
  asDate = false,
}: {
  date?: IDatePicker;
  period: string;
  market?: Market;
  asDate?: boolean;
}) => {
  if (date === undefined) return '';
  const i18n = getI18n();
  const dateC = { ...date };
  if (dateC && dateC.y === null) {
    dateC.y = new Date().getFullYear() + 2;
  }
  if (isEmpty(dateC) || isEmpty(period)) return '';

  if (market == null) {
    market = gazetteer.getMarketByCountry(CountryCodes.GB);
  }

  const weddingDate = new Date(mapToExactDate(mapDateToUI(dateC), market) as string);

  if (asDate) {
    return weddingDate && period ? applyIsoDuration(weddingDate, period) : new Date();
  }

  switch (period) {
    case '-P3M':
      return i18n.t('checklist:period.3months');
    case '-P1M':
      return i18n.t('checklist:period.1month');
    case '-P1W':
      return i18n.t('checklist:period.1week');
    case 'P':
      return i18n.t('checklist:period.weddingDay');
    case '+P':
      return i18n.t('checklist:period.after');
    default:
      return weddingDate && period ? format(applyIsoDuration(weddingDate, period), 'MMM yyyy') : '';
  }
};

export const getTaskPeriodOptions = (
  taskGroupList: Array<string>,
  date: IDatePicker | undefined,
  market: Market,
): Array<IDropdownOption> => {
  const periodOptions: Array<IDropdownOption> = [];

  if (taskGroupList) {
    taskGroupList.forEach((period) => {
      periodOptions.push({
        value: period,
        label: getPeriodName({ date, period, market }) as string,
      });
    });
  }

  return periodOptions;
};

export const isGroupOutstanding = (
  date: IDatePicker | undefined,
  period: string,
  market: Market,
): boolean => {
  if (isEmpty(date) || isEmpty(period) || !period || !date || date.y === null) return false;
  const weddingDate = new Date(mapToExactDate(mapDateToUI(date), market) as string);
  const duration = applyIsoDuration(weddingDate, period);
  return differenceInCalendarMonths(new Date(), new Date(duration)) > 0;
};

const getDone = (checklistFilter: string) => {
  switch (true) {
    case checklistFilter === 'completed':
      return true;
    case checklistFilter === 'mytasks':
    case checklistFilter === 'alltasks':
    default:
      return false;
  }
};

export const arrayToObject = <T extends { id: string }>(array: T[]) =>
  array.reduce((obj: Record<string, T>, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

const getFilterProps = (checklistFilter: string) => {
  switch (true) {
    case checklistFilter === 'mytasks':
      return whereEq({ custom: true });
    default:
      return Boolean;
  }
};

export const getPeriodsAndGroups = (
  tasks: Record<string, ITask>,
  checklistFilter: IChecklistFilter,
) => {
  const getGroupTask = (i: [string, Array<ITask>]) => {
    const [groupId, groupTasks] = i;
    const tasksDone = groupTasks.filter((t) => t.done).length;
    const tasksTotal = groupTasks.length;
    return {
      id: groupId,
      name: groupTasks[0].custom ? groupTasks[0].name : '',
      custom: groupTasks[0].custom,
      period: groupTasks[0].period,
      lastTaskOrder: groupTasks[0].custom ? groupTasks[0].order : takeLast(1, groupTasks)[0].order,
      done: groupTasks[0].custom ? groupTasks[0].done : tasksDone === tasksTotal,
      tasksDone,
      tasksTotal,
    };
  };

  const getGroups = (item: Array<ITask>) =>
    R.pipe(
      item,
      // @ts-ignore FIXME
      R.groupBy(R.prop('group')), // group by groups
      R.toPairs, // convert key value list to array of pairs
      R.map((i: [string, Array<ITask>]) =>
        getDone(checklistFilter)
          ? all(propEq('done', true), i[1])
            ? getGroupTask(i)
            : null
          : any(propEq('done', false), i[1])
          ? getGroupTask(i)
          : null,
      ), // select done / undone groups where all tasks match predicate ( all tasks done: true|false)
    ).filter(Boolean);

  const tasksSplit = R.pipe(
    values(tasks), // convert tasksList to array
    R.sortBy(R.prop<ITask, 'order'>('order')), // sort by order
    R.filter(getFilterProps(checklistFilter)), // filter by mytasks
    R.groupBy(R.prop('period')), // group by period
    R.toPairs, // convert key value list to array of pairs
    R.map((item) => [item[0], getGroups(item[1])]), // map over periods to process groups
    R.map((i) => (isEmpty(i[1]) ? null : i)), // omit empty periods
  ).filter(Boolean);

  const groupsList = arrayToObject(flatten(tasksSplit.map(([, p]: any) => p)));
  const periods = tasksSplit.reduce((obj, item) => {
    // @ts-ignore FIXME
    obj[item[0]] = item[1].map((i) => i.id);
    return obj;
  }, {});

  return [periods, groupsList];
};

export const getNextOrder = (
  period: string,
  tasks: Record<string, ITask>,
  tasksInitial: Record<string, ITask>,
  market: Market,
  date: IDatePicker,
  initializedAt: number,
) => {
  const tasksByPeriod = R.pipe(
    values(tasks),
    R.sortBy(R.prop<ITask, 'order'>('order')),
    R.filter((i) => i.period === period),
  );
  if (isEmpty(tasksByPeriod)) {
    /*
      If there are no tasks in given period we need to
      get original order from tasksInitial
    */
    const exactDate = getTasksExactDate(date, market);
    const processedTasks = getSplitTasks(tasksInitial, new Date(exactDate), initializedAt);
    return processedTasks.filter((t) => t.period === period)[0]?.order || 1;
  }
  const lastTaskOrder = takeLast(1, tasksByPeriod)[0].order;
  return fixedTo(lastTaskOrder + 0.0001, 4);
};

export const getNextSubtaskOrder = (groupId: string, tasks: Record<string, ITask>) => {
  const allTasksInGroup = R.pipe(
    values(tasks),
    R.sortBy(R.prop<ITask, 'order'>('order')),
    R.filter((t) => t.group === groupId),
  );

  const lastTaskOrder = takeLast(1, allTasksInGroup)[0].order;
  return fixedTo(lastTaskOrder + 0.0001, 4);
};

// Faster alternative to toFixed()
export const fixedTo = (num = 1, n = 1) => {
  const k = Math.pow(10, n);
  return Math.round(num * k) / k;
};

export const getTasksExactDate = (date: IDatePicker, market: Market) => {
  // if year is undecided take current year + 2
  const uiDate = mapDateToUI({
    ...date,
    y: date.y === null ? new Date().getFullYear() + 2 : date.y,
  });
  return new Date(mapToExactDate(uiDate, market));
};
