// Simple serial sync/async chriso/validator.js wrapper with promises.
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import { parseVideo } from './parse-video';
import ValidationError from './validation-error';

const defaultValidationMessages = {
  requiredMessage: (prop: string) => `Please fill out '${prop}' field.`,
  invalidEmail: 'Email address is not valid.',
  minPasswordLength: (minLength: number) =>
    `Password must contain at least ${minLength} characters.`,
  invalidUrl: 'URL is not valid.',
  invalidUKPhone: 'Please enter a valid UK phone number.',
  invalidPhone: 'Phone number is not valid.',
  invalidUrlChars:
    'The URL can only be made up of standard characters including a-z, 0-9, hyphen (-) and underscore (_).',
  invalidUKPostcode: 'Please enter a valid UK postcode',
  requiredVideoUrl: 'Please enter valid Youtube or Vimeo URL',
  requiredPositiveNumber: 'Please enter a number larger than 0',
  equals: (compareValue: string) => `Please enter ‘${compareValue}‘`,
};

export type ValidationMessages = typeof defaultValidationMessages;

export default class Validation<T extends object> {
  _object: T;
  _prop: keyof T | null;
  _messages: Partial<ValidationMessages> | null;
  promise: Promise<any>;

  constructor(object: T, messages?: Partial<ValidationMessages>) {
    this._object = object;
    this._prop = null;
    this._messages = messages;
    this.promise = Promise.resolve();
  }

  getMessages() {
    return this._messages || defaultValidationMessages;
  }

  custom(
    callback: (a: any, b: string | null, c: Object) => void,
    { required }: { required?: boolean } = {},
  ): Validation<T> {
    const prop = this._prop;
    const value = this._object[prop] ? String(this._object[prop]) : '';
    const object = this._object;
    this.promise = this.promise.then(() => {
      if (required && !this._isEmptyString(value)) return;
      callback(value, prop as string, object);
    });
    return this;
  }

  postcodeValidate(value: string, prop?: string | null) {
    const pattern = '[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?( |)[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}';
    const isValid = RegExp(pattern).test(value);
    if (isValid) return;
    throw new ValidationError(this.getMessages().invalidUKPostcode, prop);
  }

  _isEmptyString(value: string): boolean {
    return String(value).trim() === '';
  }

  prop(prop: keyof T): Validation<T> {
    this._prop = prop;
    return this;
  }

  required(
    getRequiredMessage?: (a: any, b: string) => string | { message: string; messageI18n: string },
  ): Validation<T> {
    return this.custom(
      (value: any, prop: string | null) => {
        const msg = getRequiredMessage
          ? getRequiredMessage(prop, value)
          : this.getMessages().requiredMessage(prop);

        if (typeof msg === 'object') {
          throw new ValidationError(msg.message, prop, undefined, msg.messageI18n);
        }

        throw new ValidationError(msg, prop);
      },
      { required: true },
    );
  }

  email(): Validation<T> {
    return this.custom((value, prop) => {
      /* eslint-disable */
      if (isEmail(value)) return;
      /* eslint-enable */
      throw new ValidationError(this.getMessages().invalidEmail, prop);
    });
  }

  validEmail(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '') return;
      /* eslint-disable */
      if (isEmail(value)) return;
      /* eslint-enable */
      throw new ValidationError(this.getMessages().invalidEmail, prop);
    });
  }

  simplePassword(): Validation<T> {
    return this.custom((value, prop) => {
      const minLength = 6;
      if (value.length >= minLength) return;
      throw new ValidationError(this.getMessages().minPasswordLength(minLength), prop);
    });
  }

  urLink(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '' || typeof value === 'undefined') return;
      /* eslint-disable */
      if (isURL(value) && !isEmail(value)) return;
      /* eslint-enable */
      throw new ValidationError(this.getMessages().invalidUrl, prop);
    });
  }

  phone(): Validation<T> {
    return this.custom((value, prop) => {
      try {
        // Try to parse number.
        const parsedNumber = parsePhoneNumberWithError(value);
        // If parsing is successful, check if the number is possible. We don't want to validate it, as it's too strict.
        if (!parsedNumber.isPossible()) throw new Error();

        return;
      } catch (e) {
        throw new ValidationError(this.getMessages().invalidPhone, prop);
      }
    });
  }

  mobilePhone(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '') return;
      const pattern = /^\+?\d{1,20}$/;
      const isValid = RegExp(pattern).test(value);
      if (isValid) return;
      throw new ValidationError(this.getMessages().invalidPhone, prop);
    });
  }

  postcode(): Validation<T> {
    return this.custom((value, prop) => {
      this.postcodeValidate(value, prop);
    });
  }

  postcodeNotRequired(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '') return;
      this.postcodeValidate(value, prop);
    });
  }

  urlFriendly(): Validation<T> {
    return this.custom((value, prop) => {
      const pattern = '^[a-zA-Z0-9_-]*$';
      const isValid = RegExp(pattern).test(value);
      if (isValid && value.trim() !== '') return;
      throw new ValidationError(this.getMessages().invalidUrlChars, prop);
    });
  }

  video(getVideoMessage?: (a: any, b: string | null) => string) {
    return this.custom((value, prop) => {
      const validUrl = parseVideo(value);
      if (validUrl.type === 'youtube' || validUrl.type === 'vimeo') return;
      const msg = getVideoMessage
        ? getVideoMessage(prop, value)
        : this.getMessages().requiredVideoUrl;
      throw new ValidationError(msg, prop);
    });
  }

  positiveIntNotRequired() {
    return this.custom((value, prop) => {
      if (value === '') return;
      const isValid = parseInt(value, 10) > 0;
      if (isValid) return;
      throw new ValidationError(this.getMessages().requiredPositiveNumber, prop);
    });
  }

  equals(compareValue: string, getMessage?: (compareValue?: string) => string) {
    return this.custom((value, prop) => {
      if (value === compareValue) return;
      const msg = getMessage ? getMessage(compareValue) : this.getMessages().equals(compareValue);
      throw new ValidationError(msg, prop);
    });
  }
}
