import React, { ReactNode } from 'react';
import Box from '@bridebook/ui/src/components/fela/Box';
import { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import componentStyles from './tabs.style';

interface IProps {
  stretch?: boolean;
  tabGap?: number;
  style?: FelaCSS;
  children?: ReactNode;
  onMouseLeave?: () => void;
}

const Tabs = ({ stretch = true, tabGap = 0, style = {}, children, ...restProps }: IProps) => {
  const styles = componentStyles({ tabGap, stretch });

  return (
    <Box style={mergeStyles([styles.wrapper, style])} {...restProps}>
      {children}
    </Box>
  );
};

export default Tabs;
