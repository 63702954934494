import { enquiriesServerSideTrackStart } from './actions';

export enum EnquiriesAnalyticsActionTypes {
  ENQUIRY_FORM_TOGGLE_ANALYTICS = '[Enquiries] [Analytics] Enquiry form toggle analytics',
  ENQUIRIES_SERVER_SIDE_TRACK = '[Enquiries] [Analytics] Enquiries server side track',
  ENQUIRIES_SERVER_SIDE_TRACK_SUCCESS = '[Enquiries] [Analytics] Enquiries server side track success',
  FAILED_TO_SEND_ENQUIRY_ANALYTICS = '[Enquiries] [Analytics] Failed to send enquiry analytics',
  FAILED_TO_CONFIRM_DETAILS_ANALYTICS = '[Enquiries] [Analytics] Failed to confirm details analytics',
  CONFIRMED_ENQUIRY_DETAILS_ANALYTICS = '[Enquiries] [Analytics] Confirmed enquiry details analytics',
}

export type EnquiriesServerSideTrackStartAction = ReturnType<typeof enquiriesServerSideTrackStart>;
