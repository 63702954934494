import { onFirebaseBudget } from 'lib/budget/actions/on-listener';
import {
  deleteSupplierBudget,
  initialiseDynamicBudget,
  initialiseSuccess,
  loadOnboardingData,
  saveBudgetSubitem,
  setBudgetInitFormField,
  toggleBudgetDrawer,
  toggleBudgetInitFormCategory,
  toggleCustomCostModal,
  toggleInitLoader,
  toggleScrollToItemAfterAdding,
  updateBudgetExtras,
  updateGuestsEstimate,
} from './actions';

export enum BudgetActionTypes {
  INIT_LISTENER = '[Budget] Init listener',
  STOP_LISTENER = '[Budget] Stop listener',
  ON_FIREBASE_BUDGET = '[Budget] On Firebase budget',
  TOGGLE_CUSTOM_COST_MODAL = '[Budget] Toggle custom cost modal',
  TRIGGER_RESET_BUDGET = '[Budget] Trigger reset budget',
  RESET_BUDGET = '[Budget] Confirm reset budget',
  DELETE_SUPPLIER_BUDGET = '[Budget] Delete supplier budget',
  DELETE_SUPPLIER_BUDGET_ERROR = '[Budget] Delete supplier budget error',
  HIDE_BUDGET_ERROR = '[Budget] Hide budget error',
  INITIALISE_DYNAMIC_BUDGET_ERROR = '[Budget] Initialise dynamic budget error',
  INITIALISE_DYNAMIC_BUDGET_START = '[Budget] Initialise dynamic budget start',
  INITIALISE_DYNAMIC_BUDGET_SUCCESS = '[Budget] Initialise dynamic budget success',
  INITIALISE_DYNAMIC_BUDGET_FAILED_TO_CALCULATE_ANALYTICS = '[Budget] Initialise dynamic budget failed to calculate analytics',
  TOGGLE_INIT_LOADER = '[Budget] Toggle initialise loader',
  BUDGET_INIT_COMPLETED = '[Budget] Budget init completed',
  LOAD_ONBOARDING_DATA = '[Budget] Load onboarding data',
  SAVE_BUDGET_SUBITEM = '[Budget] Save budget subitem',
  SAVE_BUDGET_SUCCESS = '[Budget] Save budget success',
  SET_BUDGET_INIT_FORM_FIELD = '[Budget] Set budget init form field',
  TOGGLE_BUDGET_INIT_FORM_CATEGORY = '[Budget] Toggle budget init form category',
  UPDATE_TARGET_BUDGET = '[Budget] Update target budget',
  UPDATE_USER_BUDGET = '[Budget] Update user budget',
  UPDATED_TOTAL_COSTS = '[Budget] Updated total costs',
  FETCH_COSTS_INITIAL_SUCCESS = '[Budget] Fetch costs initial success',
  TOGGLE_BUDGET_DRAWER = '[Budget] Toggle budget drawer',
  TOGGLE_SCROLL_TO_ITEM_AFTER_ADDING = '[Budget] Scroll to item after adding',
  UPDATE_GUESTS_ESTIMATE = '[Budget] Update guests estimate',
  UPDATE_BUDGET_EXTRAS = '[Budget] Update budget extras',
}

export type IOnFirebaseBudgetAction = ReturnType<typeof onFirebaseBudget>;

export type ILoadOnboardingDataAction = ReturnType<ReturnType<typeof loadOnboardingData>>;

export type IDeleteSupplierBudgetAction = ReturnType<typeof deleteSupplierBudget>;

export type IInitialiseSuccessAction = ReturnType<typeof initialiseSuccess>;

export type IInitialiseDynamicBudgetAction = ReturnType<typeof initialiseDynamicBudget>;

export type ISetBudgetInitFormFieldAction = ReturnType<typeof setBudgetInitFormField>;

export type IToggleBudgetInitFormCategoryAction = ReturnType<
  ReturnType<typeof toggleBudgetInitFormCategory>
>;

export type IToggleCustomModalAction = ReturnType<ReturnType<typeof toggleCustomCostModal>>;

export type ISaveBudgetSubitemAction = ReturnType<ReturnType<typeof saveBudgetSubitem>>;

export type IToggleBudgetDrawerAction = ReturnType<typeof toggleBudgetDrawer>;

export type IToggleInitLoaderAction = ReturnType<typeof toggleInitLoader>;

export type IToggleScrollToItemAfterAddingAction = ReturnType<typeof toggleScrollToItemAfterAdding>;

export type IUpdateGuestsEstimate = ReturnType<typeof updateGuestsEstimate>;

export type IUpdateBudgetExtras = ReturnType<typeof updateBudgetExtras>;
