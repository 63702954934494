import { getI18n } from 'lib/i18n/getI18n';

export default class SendEnquiryError extends Error {
  messageI18n: string;
  constructor() {
    super();
    this.message = 'Failed to send enquiry';
    this.messageI18n = getI18n().t('enquiries:failedToSend');
  }
}
