import { findIndex, isEmpty, propEq } from 'ramda';
import { mapDateFromUI, mapDateToUI } from '@bridebook/toolbox/src';
import { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import { initialiseChecklist, reInitialiseChecklist } from 'lib/checklist/actions';
import { IDeps } from 'lib/types';
import { updateWeddingField } from 'lib/weddings/actions';
import './types';

const tabs = [{ id: 'year-screen' }, { id: 'month-screen' }, { id: 'day-screen' }];

export const tabIndex = (id: string) => findIndex(propEq('id', id), tabs);

export const registerDatePicker = (
  datePickerId: string,
  datePickerUI?: IDatePickerUI | null,
  activeTab?: string,
) => ({
  type: 'REGISTER_DATEPICKER',
  payload: {
    datePickerId,
    datePickerUI,
    activeTab,
  },
});

export const changeTab = (
  datePickerId: string,
  nextTab: string,
  toValidate: boolean,
  datePickerDate: IDatePickerUI,
) => ({
  type: 'CHANGE_TAB_START',
  payload: {
    datePickerId,
    nextTab,
    toValidate,
    datePickerDate,
  },
});

export const saveWeddingDate =
  (datePickerUI: IDatePickerUI) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      weddings: {
        profile: { date },
      },
      checklist: { initialised },
    } = getState();

    const newDate = mapDateFromUI(datePickerUI);

    dispatch(updateWeddingField('date', { date: newDate }));

    // re-initialise checklist
    if (date && !isEmpty(date)) {
      if (initialised) {
        dispatch(reInitialiseChecklist()); // analytics
      }
      dispatch(initialiseChecklist(false));
    }

    return { type: 'UPDATE_WEDDING_DATE' };
  };

export const nextTab =
  (datePickerId: string) =>
  ({ getState, dispatch }: IDeps) => {
    const datepickerInstance = getState().datepicker.instances[datePickerId];
    const {
      datepicker: { activeTab },
      datePickerDate,
    } = datepickerInstance;

    const nextTabId = tabIndex(activeTab) + 1;
    if (nextTabId < tabs.length) {
      const nextTab = tabs[nextTabId].id;
      dispatch(changeTab(datePickerId, nextTab, true, datePickerDate));
      return {
        type: 'DATEPICKER_NEXT_TAB',
        payload: { datePickerId },
      };
    }
    return {
      type: 'DATEPICKER_DATE_VALID',
      payload: { datePickerId },
    };
  };

export const resetDatepickerDate = (datePickerId: string, newDateObject: object = {}) => ({
  type: 'RESET_DATEPICKER_DATE',
  payload: { newDateObject, datePickerId },
});

export const changeDatepickerDate = (
  datePickerId: string,
  name: string,
  value: string | number,
  method: string = 'button',
) => ({
  type: 'CHANGE_DATEPICKER_DATE_START',
  payload: { name, value, datePickerId, method },
});

export const cancelDatepickerEdit = (datePickerId: string, track: boolean = true) => ({
  type: 'CANCEL_DATEPICKER_EDIT',
  payload: { datePickerId, track },
});

export const startDatepickerDateEdit = (datePickerId: string, date: IDatePickerUI | null) => ({
  type: 'START_DATEPICKER_DATE_EDIT',
  payload: { date, datePickerId },
});

export const toggleDatePicker = (datePickerId: string, modalState: boolean = true) => ({
  type: 'TOGGLE_DATEPICKER',
  payload: { modalState, datePickerId },
});

export const datepickeDateEdit =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const { date } = getState().weddings.profile;
    const datePickerUI = mapDateToUI(date);
    dispatch(startDatepickerDateEdit('weddingDate', datePickerUI));
    dispatch(toggleDatePicker('weddingDate', true));
    return {
      type: 'START_DATEPICKER-NOOP',
    };
  };

export const changeTabSuccess = (payload: object) => ({
  type: 'DATEPICKER_CHANGE_TAB_SUCCESS',
  payload,
});

export const tabClickedByUser = () => ({
  type: 'TAB_CLICKED_BY_USER',
});

interface ITabClicked {
  activeTab: string;
  datePickerUI: IDatePickerUI;
  datePickerId: string;
  datepickerTabs: Array<any>;
  tabId: string;
}

export const tabClicked =
  ({ activeTab, datePickerUI, datePickerId, datepickerTabs, tabId }: ITabClicked) =>
  ({ dispatch }: IDeps) => {
    dispatch(tabClickedByUser());
    const nextTabIndex = datepickerTabs.findIndex((tab) => tab.id === tabId);
    const currentTabIndex = datepickerTabs.findIndex((tab) => tab.id === activeTab);
    const toValidate = nextTabIndex > currentTabIndex;
    dispatch(changeTab(datePickerId, tabId, toValidate, datePickerUI));
    return { type: 'TAB_CLICKED' };
  };
