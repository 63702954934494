import { filter, values } from 'ramda';
import { ValidationError } from '@bridebook/toolbox/src';
import { EnquiryIntents } from '@bridebook/toolbox/src/types';
import getDefaultEnquiryCheckboxValue from 'lib/enquiries/utils/get-default-enquiry-intent-checkbox-value';

const validateEnquiryIntent = ({
  intent,
  contextName,
}: {
  intent: EnquiryIntents;
  contextName: string;
}) => {
  const showCheckboxes = Boolean(getDefaultEnquiryCheckboxValue({ contextName }));

  if (showCheckboxes) {
    const allTruthy = filter((checked) => checked, intent as unknown as EnquiryIntents);
    if (values(allTruthy).length < 1) {
      throw new ValidationError('Please choose at least one option to continue.', 'intent');
    }
  }

  return true;
};

export default validateEnquiryIntent;
