import { contains } from 'ramda';
import React, { SyntheticEvent } from 'react';
import { Accordion } from '@bridebook/ui';
import { IProps as IAccordionProps } from '@bridebook/ui/src/components/bbcommon/accordion/accordion';

export interface IProps extends IAccordionProps {
  name: string;
  onClick?: (opened: boolean) => void;
  disabled?: boolean;
  accordions: string[];
  toggleContentAccordion: (name: string) => void;
  horizontalMargins?: boolean;
}

const ContentAccordion = ({
  name,
  onClick,
  disabled,
  accordions,
  toggleContentAccordion,
  ...restProps
}: IProps) => {
  const isOpened = (): boolean => contains(name, accordions);
  const openToggle = (e?: SyntheticEvent<any>): void => {
    if (e) {
      e.preventDefault();
    }

    if (!disabled) {
      toggleContentAccordion(name);
    }
  };

  const accordionClick = (): void => {
    const opened = isOpened();
    openToggle();

    if (onClick) {
      onClick(opened);
    }
  };

  const opened = isOpened();
  return <Accordion {...{ disabled, ...restProps, opened }} onClick={accordionClick} />;
};

export default ContentAccordion;
