import { createSelector } from 'reselect';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { createCoupleChatUserOptions } from '@bridebook/toolbox/src/inbox/utils';
import { getCountryCodeWithFallback } from 'lib/i18n/selectors';
import { IApplicationState } from 'lib/types';
import { selectUserLocale } from 'lib/users/selectors';
import { createDeepEqualSelector } from 'lib/utils/selectors';

const _profileId = (state: IApplicationState) => state.weddings.profile.id;
const _partners = (state: IApplicationState) => state.weddings.profile.partners;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

/**
 * Returns object necessary to initialize chat session
 */
export const getCoupleChatUserOptions = createDeepEqualSelector(
  createSelector(
    [_profileId, _partners, getCountryCodeWithFallback, selectUserLocale],
    (profileId, partners, countryCode, locale) => {
      if (!profileId) return null;
      return createCoupleChatUserOptions({
        profileId,
        partners,
        countryCode,
        locale: locale || gazetteer.getMarketByCountry(countryCode).locale,
      });
    },
  ),
  (obj) => obj,
);
