import { IUISupplier } from '@bridebook/toolbox/src/types';
import { IDeps } from '../../../types';
import { EnquiriesActionTypes } from '../../action-types';
import { EnquiriesAnalyticsActionTypes } from '../action-types';

interface IEnquiriesServerSideTrackStartArgs {
  supplier?: IUISupplier;
  event: string;
  infoProps?: Record<string, any>;
}

export const failedToSendEnquiryAnalytics =
  (error: Error, supplier?: IUISupplier) =>
  ({ dispatch }: IDeps) => {
    dispatch(
      enquiriesServerSideTrackStart({
        event: 'Failed to send enquiry',
        infoProps: { errorMessage: error.message },
        supplier,
      }),
    );

    return {
      type: EnquiriesAnalyticsActionTypes.FAILED_TO_SEND_ENQUIRY_ANALYTICS,
    };
  };

export const failedToConfirmDetailsAnalytics =
  (error: Error) =>
  ({ dispatch }: IDeps) => {
    dispatch(
      enquiriesServerSideTrackStart({
        event: 'Failed to confirm details',
        infoProps: {
          errorMessage: error.message,
        },
      }),
    );

    return {
      type: EnquiriesAnalyticsActionTypes.FAILED_TO_CONFIRM_DETAILS_ANALYTICS,
    };
  };

export const confirmedEnquiryDetailsAnalytics =
  (enquiryDetailsConfirmLocation: string) =>
  ({ dispatch }: IDeps) => {
    dispatch(
      enquiriesServerSideTrackStart({
        event: 'Confirmed enquiry details',
        infoProps: {
          enquiryDetailsConfirmLocation,
        },
      }),
    );

    return {
      type: EnquiriesAnalyticsActionTypes.CONFIRMED_ENQUIRY_DETAILS_ANALYTICS,
    };
  };

export const enquiriesServerSideTrackStart = (payload: IEnquiriesServerSideTrackStartArgs) => ({
  type: EnquiriesAnalyticsActionTypes.ENQUIRIES_SERVER_SIDE_TRACK,
  payload,
});

export const sendEnquirySuccessAnalytics = ({
  contactedSupplier,
  contactLocation,
  enquiryId,
  inboxMessageSent,
  isFromExternalPage,
  contactType,
  contactMethod,
  linkedSupplierName,
  linkedSupplierId,
}: {
  contactedSupplier: IUISupplier;
  contactLocation?: string;
  enquiryId: string;
  inboxMessageSent: boolean;
  isFromExternalPage?: boolean;
  contactType?: string;
  contactMethod?: string;
  linkedSupplierName?: string;
  linkedSupplierId?: string;
}) => ({
  type: EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS_ANALYTICS,
  payload: {
    contactedSupplier,
    contactLocation,
    id: enquiryId,
    inboxMessageSent,
    isFromExternalPage,
    contactType,
    contactMethod,
    linkedSupplierName,
    linkedSupplierId,
  },
});
