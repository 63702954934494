import React, { Component, ReactNode } from 'react';
import { Collapse, UnmountClosed } from 'react-collapse';
import { IStylingProps } from '../../../themes/types';
import Box from '../../fela/Box';
import AccordionHead, { type SharedTypes as IAccordionHeadProps } from './accordion-head';

export interface IProps extends IStylingProps {
  id?: string;
  children?: ReactNode;
  /**
   * Function called when clicking the header.
   * Overrides default expand/collapse behaviour.
   */
  onClick?: Function | null;
  /** Text displayed in the AccordionHeader. */
  text?: string | ReactNode;
  /** Number displayed in brackets after text in the AccordionHeader. */
  itemAmount?: string | number | null;
  /** Additional child rendered after the AccordionHeader's text and itemAmount. */
  headCustom?: ReactNode;
  /** Indicates whether the Header text should have a slightly muted colour. */
  disabled?: boolean;
  /**
   * Indicates whether the Header should not render the arrow on the far right.
   * Does not affect the ability to expand or collapse the contents of the Accordion.
   * @default false
   */
  hideArrow?: boolean;
  /**
   * Indicates whether the Accordion should always render its contents.
   *
   * This causes the AccordionHeader to never render the arrow element.
   *
   * This also causes the Accordion to ignore the opened state and always keep its contents open.
   *
   * Finally, removes the pointer style when hovering cursor over AccordionHead.
   *
   * Does not affect the ability to call functions passed to onClick or the toggle method.
   */
  openStatic?: boolean;
  /** If set to true, the AccordionHeader's border will not sync colour with backgroundColor after opening its contents. */
  leaveBorder?: boolean;
  /** If set to true, removes the left and right paddings of the AccordionHeader's container. */
  noSidePaddings?: boolean;
  /**
   *  If set to true, removes the AccordionHeader container's border and rounded corners.
   *
   *  Only affects desktop styles.
   *
   *  Does not hide the top border unless `noTopBorder` is `true`.
   */
  noRoundBorder?: boolean;
  /** Indicates whether the Header background should be transparent. */
  noBgColor?: boolean;
  /**
   * Sets a fixed height for the container.
   * @default 60
   */
  containerHeight?: number;
  /**
   * Indicates whether the Header's top border should be hidden.
   *
   * Works standalone on mobile and tablet view.
   *
   * Requires `noRoundBorder` to be `true` for desktop views.
   */
  noTopBorder?: boolean;
  /** Indicates whether the Accordion's contents should be expanded. */
  opened?: boolean;
  /** Indicates whether the AccordionHeader's wrapper should have horizontal and vertical margins. */
  horizontalMargins?: boolean;
  /**
   * Indicates whether the AccordionHeader's wrapper should use the fixed height value.
   * If `true`, the value of `containerHeight` is ignored.
   */
  noFixedHeight?: boolean;
  /**
   * Changes the directions of arrows when Accordion's contents are expanded or collapsed.
   *
   * Default behaviour: arrow points to the right if collapsed, points down if expanded.
   *
   * `arrowStartDown` is `true` behaviour: arrow points down if collapsed, points up if expanded.
   */
  arrowStartDown?: boolean;
  /**
   * Function called when clicking the header, after handling the default collapse/expand state change.
   * Only applicable if `onClick` is not truthy.
   */
  onToggle?: (isOpened: boolean) => void;
  /** Keeps collapsible content mounted at all times (useful for SEO) */
  alwaysMounted?: boolean;
  /**
   * Indicates which variant of the AccordionHeader's style set is to be used.
   *
   * If `variant` is `h2`, the following properties are overridden:
   * - `containerMinHeight` is set to `40`
   * - `noSidePaddings` is set to `true`
   * - `noFixedHeight` is set to `true`
   * - `noBgColor` is set to `true`
   * - `noRoundBorder` is set to `true`
   *
   * Additionally, the following changes are applied:
   * - AccordionHeader's wrapper is `h2` instead of `div`
   * - AccordionHeader's wrapper has vertical margins removed
   * - AccordionHeader's text `lineHeight` is `27px`
   * - AccordionHeader's text uses `fontDefaultSemiBold: 22`
   */
  variant?: 'normal' | 'h2';
}

interface IState {
  isOpened: boolean;
  prevOpened: boolean;
}

class Accordion extends Component<IProps, IState> {
  state = {
    isOpened: this.props.opened || false,
    prevOpened: this.props.opened || false,
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.opened !== state.prevOpened) {
      return {
        isOpened: props.opened || false,
        prevOpened: props.opened,
      };
    }

    return null;
  }

  toggle = (): void => {
    this.setState({ isOpened: !this.state.isOpened });

    this.props.onToggle?.(!this.state.isOpened);
  };

  render() {
    const {
      id,
      children,
      onClick,
      text,
      headCustom,
      itemAmount,
      disabled,
      hideArrow,
      openStatic,
      leaveBorder,
      noSidePaddings,
      noRoundBorder,
      noBgColor,
      containerHeight,
      noTopBorder,
      horizontalMargins,
      noFixedHeight,
      arrowStartDown,
      alwaysMounted,
      variant = 'normal',
      ...restProps
    } = this.props;

    const wrapperProps = { ...restProps };
    delete wrapperProps.opened;
    const accordionProps: IAccordionHeadProps =
      variant === 'h2'
        ? {
            containerMinHeight: 40,
            noSidePaddings: true,
            noFixedHeight: true,
            noBgColor: true,
            noRoundBorder: true,
          }
        : {};

    return (
      <Box {...wrapperProps}>
        <AccordionHead
          onClick={onClick || this.toggle}
          id={id}
          text={text}
          headCustom={headCustom}
          itemAmount={itemAmount}
          disabled={disabled}
          leaveBorder={leaveBorder}
          noSidePaddings={noSidePaddings}
          noTopBorder={noTopBorder}
          noRoundBorder={noRoundBorder}
          noBgColor={noBgColor}
          containerHeight={containerHeight}
          openStatic={openStatic}
          hideArrow={hideArrow || openStatic}
          opened={openStatic || this.state.isOpened}
          horizontalMargins={horizontalMargins}
          noFixedHeight={noFixedHeight}
          arrowStartDown={arrowStartDown}
          variant={variant}
          {...accordionProps}
        />
        {openStatic ? (
          <Box>{children}</Box>
        ) : alwaysMounted && children ? (
          <Collapse isOpened={this.state.isOpened}>{children}</Collapse>
        ) : (
          <UnmountClosed isOpened={this.state.isOpened}>{children}</UnmountClosed>
        )}
      </Box>
    );
  }
}

export default Accordion;
