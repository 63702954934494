import { basicFields } from '@bridebook/toolbox/src/google/maps';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { Locales } from 'lib/i18n/constants';

export class GooglePlacesService {
  #autocompleteService: google.maps.places.AutocompleteService | undefined = undefined;
  #placesService: google.maps.places.PlacesService | undefined = undefined;
  #autocompleteRequestArea = 720;

  private sessionToken: google.maps.places.AutocompleteSessionToken | undefined = undefined;

  async getPredictions(input: string, options?: google.maps.places.AutocompleteOptions) {
    this.initGoogleServices();

    if (!this.sessionToken) {
      this.createSessionToken();
    }

    const inputRequest: google.maps.places.AutocompletionRequest = options
      ? {
          input,
          sessionToken: this.sessionToken,
          ...options,
        }
      : {
          componentRestrictions: { country: Locales.UK },
          input,
          location: new google.maps.LatLng({ lat: 53.7295812, lng: -1.4396036 }),
          radius: 1000 * this.#autocompleteRequestArea, // set circle area of 720km from UK center
          sessionToken: this.sessionToken,
          types: ['geocode'],
        };

    const predictions = await this.getPlacePredictions(inputRequest);

    return predictions;
  }

  getPlaceDetails(predictions: google.maps.places.AutocompletePrediction[] = []) {
    const { placesService } = this.initGoogleServices();

    if (this.sessionToken == null) {
      this.createSessionToken();
    }

    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      placesService.getDetails(
        {
          fields: basicFields,
          placeId: predictions[0]?.place_id,
          sessionToken: this.sessionToken,
        },
        (place, status) => {
          this.deleteSessionToken();

          if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry) {
            resolve(place);
          } else {
            reject(new Error(status));
          }
        },
      );
    });
  }

  async getPlacePredictions(inputRequest: google.maps.places.AutocompletionRequest) {
    const { autocompleteService } = this.initGoogleServices();

    if (!this.sessionToken) {
      this.createSessionToken();
    }

    try {
      const { predictions } = await autocompleteService.getPlacePredictions({
        sessionToken: this.sessionToken,
        ...inputRequest,
      });

      return predictions;
    } catch (e) {
      SentryMinimal().captureException(e, {
        tags: {
          source: 'GooglePlacesService',
        },
      });
      throw e;
    }
  }

  private createSessionToken() {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  private deleteSessionToken() {
    this.sessionToken = undefined;
  }

  /**
   * Because of how we initialize services it's very hard to use dependency injection.
   * First we create service instance and then the `google` object is attached to `window`
   * object. That's why we need to initialized services on very first call of any of public method.
   */
  private initGoogleServices() {
    if (!this.#autocompleteService) {
      this.#autocompleteService = new google.maps.places.AutocompleteService();
    }

    if (!this.#placesService) {
      this.#placesService = new google.maps.places.PlacesService(document.createElement('div'));
    }

    return { autocompleteService: this.#autocompleteService, placesService: this.#placesService };
  }
}
