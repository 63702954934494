import EnquiryIntent from '../enquiryIntent';
import { EnquiryIntents } from './types';

/**
 * Enquiry intent checkboxes schema. This schema is used to define the intent checkboxes in the
 * enquiry form. The Brochure checkbox is checked by default.
 */

const EnquiryIntentCheckboxes: EnquiryIntents = {
  ...EnquiryIntent,
  brochure: true,
};

export default EnquiryIntentCheckboxes;
