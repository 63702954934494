import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { Coordinates } from '../elastic-sync/utils/counties-coordinates';

export type CustomArea = {
  id: string;
  place_id: string;
  name: string;
  subtext?: string;
  country: string;
  custom: true;
  section: string;
  county: string;
  center: Coordinates;
};
export const customAreas: CustomArea[] = [
  {
    id: 'west-central-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|west-central',
    country: 'england',
    section: 'england',
    name: 'West Central London',
    subtext: 'From Chelsea & Paddington to Soho & Covent Garden',
    county: 'Greater London',
    center: {
      lat: 51.50661,
      lon: -0.15441,
    },
  },
  {
    id: 'east-central-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|east-central',
    country: 'england',
    section: 'england',
    name: 'East Central London',
    subtext: 'From Farringdon & Borough to Whitechapel & Bethnal Green',
    county: 'Greater London',
    center: {
      lat: 51.51311,
      lon: -0.08403,
    },
  },
  {
    id: 'north-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|north',
    country: 'england',
    section: 'england',
    name: 'North London',
    subtext: 'From Islington & Belsize Park to Willesden & Wood Green',
    county: 'Greater London',
    center: {
      lat: 51.59468,
      lon: -0.16028,
    },
  },
  {
    id: 'east-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|east',
    country: 'england',
    section: 'england',
    name: 'East London',
    county: 'Greater London',
    subtext: 'From Hackney & Mile End to Chingford & Walthamstow',
    center: {
      lat: 51.55952,
      lon: 0.01228,
    },
  },
  {
    id: 'south-east-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|south-east',
    country: 'england',
    section: 'england',
    name: 'South East London',
    subtext: 'From Camberwell & Crystal Palace to Woolwich & Abbey Wood',
    county: 'Greater London',
    center: {
      lat: 51.45977,
      lon: -0.00243,
    },
  },
  {
    id: 'south-west-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|south-west',
    country: 'england',
    section: 'england',
    name: 'South West London',
    subtext: 'From Fulham & Brixton to Wimbledon & Tooting',
    county: 'Greater London',
    center: {
      lat: 51.45206,
      lon: -0.19463,
    },
  },
  {
    id: 'west-london',
    custom: true,
    place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI|west',
    country: 'england',
    section: 'england',
    name: 'West London',
    subtext: 'From Kensington & Maida Vale to West Ealing & Acton',
    county: 'Greater London',
    center: {
      lat: 51.50895,
      lon: -0.26675,
    },
  },
];

const customAreasIds = customAreas.map((customArea) => customArea.id);

export const isSearchAreaCustomArea = (searchArea: string) =>
  customAreasIds.includes(searchArea.replace(/\s/g, '-').toLowerCase());

export const countiesSplitIntoCustomAreas = { [CountryCodes.GB]: ['Greater London'] };
