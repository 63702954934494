export enum TrackingEvent {
  ViewedSupplierProfile = 'Viewed supplier profile',
  ShortlistedSupplier = 'Shortlisted supplier',
  InitializedChecklist = 'Initialised checklist',
  UpdatedMainTasks = 'Updated main tasks',
  UpdatedSubtasks = 'Updated subtasks',
  InitializedGuestList = 'Initialised guest list',
  AddedMultipleGuests = 'Added multiple guests',
  AddedGuest = 'Added guest',
  CalculatedBudget = 'Calculated budget',
  UpdatedBudget = 'Updated budget',
  UpdatedBudgetItem = 'Updated budget item',
  SendEnquiry = 'Send enquiry',
}
