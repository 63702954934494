import { values } from 'ramda';
import { Weddings } from '@bridebook/models';
import type { Slug } from '@bridebook/toolbox/src/types';
import { IDeps } from 'lib/types';
import { BudgetActionTypes } from '../action-types';
import { findCostByType, getBookedTotalInCategory } from '../utils';

interface ISetCost {
  actual: number;
  estimate?: number;
}

export const updateUserBudget =
  (supplierType: Slug) =>
  ({ getState }: IDeps) => {
    const {
      shortlist: { list },
      weddings: { profile },
      budget: { costs },
    } = getState();

    if (supplierType && typeof profile.budget !== 'undefined') {
      const bookedVal = getBookedTotalInCategory(values(list), supplierType);

      const cost = findCostByType(values(costs), supplierType);
      const setCost: ISetCost = {
        actual: bookedVal,
      };

      if (cost) {
        Weddings._.getById(profile.id).Costs.getById(cost.id).set(setCost);
      }
    }

    return {
      type: BudgetActionTypes.UPDATE_USER_BUDGET,
    };
  };
