import { IChecklistGroup } from 'lib/checklist/types';

export const changedMainTaskPeriodAnalytics = (group: IChecklistGroup) => ({
  type: 'CHANGED_MAIN_TASK_PERIOD_ANALYTICS',
  payload: group,
});

export const updatedSubtaskAnalytics = (payload: {
  subtaskId: string;
  name?: string;
  checked: boolean;
  method: 'passive' | 'active';
}) => ({
  type: 'UPDATE_SUBTASK_ANALYTICS',
  payload,
});
