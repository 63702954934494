export enum BudgetGuestsEstimate {
  undecided = 'undecided',
  '<50' = '49',
  '50-99' = '99',
  '100-149' = '149',
  '150-250' = '249',
  '>250' = '250',
}

export enum BudgetWeekDay {
  undecided = 'undecided',
  midweek = 'midweek',
  friday = 'fri',
  saturday = 'sat',
  sunday = 'sun',
}

export enum BudgetTimeOfYear {
  undecided = 'undecided',
  peak = 'season',
  offpeak = 'off-season',
  christmas = 'christmas',
}
