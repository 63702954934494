const SPAM_REGEX_PATTERNS = [
  /\b(?:instagram)\b/i,
  /\b(?:buesdenew)\b/i,
  /\b(?:cyluee1990)\b/i,
  /\b(?:newweep223)\b/i,
  /\b(?:matterport-technologie)\b/i,
  /\bMy(?: account on)? Insta(?:gram)?(?: account)?:/i,
];

/**
 * Replace all instances of urls with [url hidden] to prevent spam
 * @param text
 */
export const filterOutUrls = (text: string) =>
  (text || '').replace(/\b(?:https?:[/][/]|www[.])\S+/gi, `[url hidden]`);

/**
 * Check whether message contains deny-listed words
 * @param message
 */
export const isSpam = (message: string) =>
  SPAM_REGEX_PATTERNS.some((pattern) => pattern.test(message));
