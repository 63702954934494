import { CohortNames } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { getI18n } from 'lib/i18n/getI18n';

export const getEnquiryCTAText = (
  isVenue: boolean,
  isLoggedOut: boolean,
  LIVE_22546_variant: CohortNames,
): string => {
  if (!isVenue) return getI18n().t('common:enquiryCTA.nonVenue');

  if (isLoggedOut || LIVE_22546_variant === 'control')
    return getI18n().t('common:cta.requestBrochure');

  switch (LIVE_22546_variant) {
    case '1': {
      return getI18n().t('common:cta.requestBrochure.v1');
    }
    case '2': {
      return getI18n().t('common:cta.requestBrochure.v2');
    }
    default: {
      return getI18n().t('common:cta.requestBrochure');
    }
  }
};
