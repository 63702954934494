import React, { FunctionComponent } from 'react';
import Box from '../../../components/fela/Box';
import mergeStyles from '../../../fela-utils/merge-styles';
import { IStylingProps } from '../../../themes/types';
import { TColor } from '../../../types';
import componentStyles from './pill.style';

type BoxProps = Parameters<typeof Box>[0];

export interface ISharedTypes {
  height?: number;
  color?: TColor;
  sidePaddings?: number;
  noSidePaddings?: boolean;
  bordered?: boolean;
  style?: Parameters<typeof Box>[0]['style'];
  background?: string;
}

interface IProps extends BoxProps, ISharedTypes, IStylingProps {}

export const Pill: FunctionComponent<IProps> = ({
  height,
  color,
  sidePaddings,
  children,
  noSidePaddings,
  bordered,
  style,
  onClick,
  background,
  ...restProps
}) => {
  const styles = componentStyles({
    height,
    color,
    sidePaddings,
    noSidePaddings,
    bordered,
    background,
  });

  return (
    <Box
      style={style ? mergeStyles([styles.wrapper, style]) : styles.wrapper}
      data-name="pill"
      onClick={onClick}
      {...restProps}>
      {children}
    </Box>
  );
};

export default Pill;
