import { FelaCSSWithCustomSelectors } from '../../../components/fela/flowtypes';

interface IStyleProps {
  tabGap: number;
  stretch: boolean;
}

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
}

const styles = ({ tabGap, stretch }: IStyleProps): IStyles => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: tabGap,
    rowGap: 0,
    width: stretch ? '100%' : 'auto',

    '& [data-element="tab"]': {
      flex: stretch ? '1' : '0',
      zIndex: 1,
    },
  },
});

export default styles;
