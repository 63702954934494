import { differenceInCalendarMonths, subMonths } from 'date-fns';
import {
  addIndex,
  equals,
  filter,
  flatten,
  head,
  last,
  lensProp,
  map,
  omit,
  over,
  prop,
  sortBy,
  values,
} from 'ramda';
import * as R from 'remeda';
import { ITask as ITaskInitial } from '@bridebook/models/source/models/Countries/Tasks.types';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';

const STATIC_PERIODS = ['-P3M', '-P1M', '-P1W', 'P', '+P'];
const STATIC_MONTHS_OFFSET = 3;

const mapIndexed = addIndex(map); // ramda util

/**
 * Split array of tasks in number of months - sub arrays chunks
 */
const splitByChunks = (tasks: ITaskInitial[][], months: number) => {
  const len = tasks.length;
  const out = [];
  let i = 0;
  while (i < len) {
    const size = Math.ceil((len - i) / months);
    months -= 1;
    out.push(tasks.slice(i, (i += size)));
  }
  return out;
};

/**
 * sort tasks by 'order'.
 * split tasks by dynamic and static periods.
 * dynamic periods will be converted to months later.
 */
const splitTasks = (tasks: ITaskInitial[]) => {
  const dynamicPeriodTasks: Array<ITaskInitial | ITask> = filter(
    (t) => !STATIC_PERIODS.includes(t.period!),
    tasks,
  );
  const predefinedPeriodTasks: Array<ITaskInitial | ITask> = filter(
    (t) => STATIC_PERIODS.includes(t.period!),
    tasks,
  );
  return [dynamicPeriodTasks, predefinedPeriodTasks];
};

/**
 * Calculate new startDate
 * if selected date is less then 6 months from now startDate is in the past.
 * if selected date is more than 30 months in the future startDate is in the future.
 * returns new startDate
 * https://bridebook.atlassian.net/browse/LIVE-8614
 */
const getStartDate = (weddingDate: Date, initializedAt?: number) => {
  let startDate = initializedAt ? new Date(initializedAt) : Date.now();
  const monthDiff = differenceInCalendarMonths(weddingDate, startDate);
  if (monthDiff < 6) {
    startDate = new Date(subMonths(weddingDate, 6)).getTime();
  }
  if (monthDiff > 30) {
    startDate = new Date(subMonths(weddingDate, 30)).getTime();
  }
  return startDate;
};

/**
 * map over tasks grouped by 'month' and 'group' and set period based on month index.
 */
const setPeriods = (
  groups: ITaskInitial[][][],
  diffInMonths: number,
): Array<ITaskInitial | ITask> =>
  mapIndexed((group, index) =>
    map(map(over(lensProp('period'), () => `-P${diffInMonths - index + STATIC_MONTHS_OFFSET}M`)))(
      group as ITaskInitial[][],
    ),
  )(groups);

/**
 * Type guard to identify Wedding ITask
 */
function isUserTask(arg: ITaskInitial | ITask): arg is ITask {
  return (arg as ITask).custom !== undefined;
}

/**
 * Don't save names from seed tasks, accessed directly from countries tasks.
 * Hide certain tasks based on Wedding roles. https://bridebook.atlassian.net/browse/LIVE-12075
 */
export const sortAndOmitNamesAndRoles = (
  tasks: Record<string, ITaskInitial | ITask>,
  weddingRoles?: IWedding['roles'],
  isSameSexRoles?: boolean,
) => {
  const tasksSorted = sortBy(prop('order'))(values(tasks));

  const getRolesWhitelistedTasks = (task: ITaskInitial): boolean => {
    if (task.roles && isSameSexRoles && weddingRoles) {
      return equals(weddingRoles, task.roles.split('-'));
    }
    return true;
  };

  return tasksSorted
    .filter(getRolesWhitelistedTasks)
    .map((task) => (isUserTask(task) && task.custom ? task : omit(['name', 'roles'], task)));
};

/**
 Get months diff between now and weddingDate minus 3 months.
 last 3 months are reserved for tasks with predefined periods.
 If absolute flag is on, use now as a start date.
 */
type GetDiffInMonthsOptions = {
  absolute?: boolean;
};

const getDiffInMonths = (
  weddingDate: Date,
  initializedAt?: number,
  options?: GetDiffInMonthsOptions,
) => {
  const endDate = subMonths(weddingDate, STATIC_MONTHS_OFFSET);
  const startDate = options?.absolute ? Date.now() : getStartDate(weddingDate, initializedAt);
  return differenceInCalendarMonths(endDate, startDate);
};

/**
 If last task order of dynamic set is higher than first task order of predefined set
 recalculate orders of predefined set starting with last task order of dynamic set + 1
 */
const validateOrdering = (
  withPeriods: (ITaskInitial | ITask)[],
  predefinedPeriodTasks: (ITaskInitial | ITask)[],
) => {
  const lastItemDynamicTask = last(flatten(withPeriods));
  const firstItemPredefinedTask = head(predefinedPeriodTasks);
  if (!firstItemPredefinedTask || !lastItemDynamicTask) return predefinedPeriodTasks;

  if ((lastItemDynamicTask?.order || 0) > (firstItemPredefinedTask?.order || 0)) {
    const newPredefinedOrderStart = (lastItemDynamicTask.order ?? 0) + 1;
    return predefinedPeriodTasks.map((t, i) => ({
      ...t,
      order: newPredefinedOrderStart + i,
    }));
  }

  return predefinedPeriodTasks;
};

export const processTasks = (
  tasks: Record<string, ITaskInitial | ITask>,
  weddingDate: Date,
  initializedAt?: number,
  weddingRoles?: IWedding['roles'],
  isSameSexRoles?: boolean,
) => {
  const tSorted = sortAndOmitNamesAndRoles(tasks, weddingRoles, isSameSexRoles);
  const [dynamicPeriodTasks, predefinedPeriodTasks] = splitTasks(tSorted);

  // group tasks by 'group' prop in order to make sure split is done on main task context
  // and avoid situations tasks from the same group split to two months
  // @ts-ignore FIXME
  const groupedTasks = R.groupBy<ITaskInitial>(dynamicPeriodTasks, R.prop('group'));

  const diffInMonths = getDiffInMonths(weddingDate, initializedAt);

  // determine amount of tasks per month
  // splits collection into slices of the specified length
  const groupsSplitByMonths = splitByChunks(values(groupedTasks), diffInMonths);

  // calculate periods, map over deeply nested arrays ( months, groups )
  const withPeriods = setPeriods(groupsSplitByMonths, diffInMonths);

  // validate correct tasks order
  const predefinedPeriodTasksValidated = validateOrdering(withPeriods, predefinedPeriodTasks);

  // flatten and merge dynamic period tasks with predefined period tasks.
  return flatten(withPeriods).concat(predefinedPeriodTasksValidated);
};

export const getSplitTasks = (
  tasks: Record<string, ITaskInitial | ITask>,
  weddingDate: Date,
  initializedAt?: number,
  weddingRoles?: IWedding['roles'],
  isSameSexRoles?: boolean,
) => processTasks(tasks, weddingDate, initializedAt, weddingRoles, isSameSexRoles);

export const sortPeriods = (periods: string[]): string[] => {
  const beforePeriods = periods.filter((period) => period[0] === '-');
  const period = periods.filter((period) => period === 'P');
  const afterPeriods = periods.filter((period) => period[0] === '+');

  const sortedBeforePeriods = beforePeriods.sort((a, b) => {
    if (a.includes('W') && b.includes('M')) return 1;
    if (b.includes('W') && a.includes('M')) return -1;

    const strippedA = a.replace(/[-PMW]/g, '');
    const strippedB = b.replace(/[-PMW]/g, '');

    return parseInt(strippedB) - parseInt(strippedA);
  });

  return [...sortedBeforePeriods, ...period, ...afterPeriods];
};

/**
 Get list of periods from start date to wedding date
 */
export const getAllPeriodList = (
  weddingDate: Date,
  initializedAt?: number,
  options?: GetDiffInMonthsOptions,
) => {
  const diffInMonths = getDiffInMonths(weddingDate, initializedAt, options);
  const periods = [];
  for (let i = 0; i < diffInMonths; i++) {
    periods.push(`-P${diffInMonths - i + STATIC_MONTHS_OFFSET}M`);
  }
  return periods.concat(STATIC_PERIODS);
};
