import Router from 'next/router';
import { values } from 'ramda';
import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import { ITask as ISeedTask } from '@bridebook/models/source/models/Countries/Tasks.types';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { ChecklistActionTypes } from 'lib/checklist/action-types';
import { updatedSubtaskAnalytics } from 'lib/checklist/analytics/actions';
import { IChecklistFilter, IChecklistGroup } from 'lib/checklist/types';
import { extractId } from 'lib/checklist/utils';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { IDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

export const closeSubtasksView =
  (onBack = false) =>
  ({ getState }: IDeps) => {
    const {
      app: { previousPath },
    } = getState();

    if (onBack && previousPath) {
      Router.back();
    } else {
      Router.push(UrlHelper.checklist.main, undefined, { scroll: false });
    }

    return {
      type: ChecklistActionTypes.CLOSE_SUBTASKS,
    };
  };

export const clearSubtask = () => ({
  type: ChecklistActionTypes.CLOSE_SUBTASKS,
});

export const updateTaskSuccess = ({ task, checked }: { task: ITask; checked: boolean }) => ({
  type: ChecklistActionTypes.UPDATE_TASK_SUCCESS,
  payload: { task, checked },
});

export const updatedChecklistTotals = ({ done, total }: { done: number; total: number }) => ({
  type: ChecklistActionTypes.UPDATED_CHECKLIST_TOTALS,
  payload: { done, total },
});

export const fetchTasksInitialSuccess = (
  payload: Record<string, ITask & Required<Pick<ISeedTask, 'i18nKey'>>>,
) => ({
  type: ChecklistActionTypes.FETCH_TASKS_INITIAL_SUCCESS,
  payload,
});

export const fetchUserChecklistSuccess = (payload: {
  result: Record<string, ITask>;
  source: string;
}) => ({
  type: ChecklistActionTypes.FETCH_USER_CHECKLIST_SUCCESS,
  payload,
});

export const showSubtasks =
  (id: string, server: boolean) =>
  ({ dispatch }: IDeps) => {
    const taskId: string = extractId(id) || '';
    const dispatchToggleDrawer = () => {
      dispatch({ type: 'SHOW_DETAILS_OPENED_ANALYTICS' });
    };
    if (taskId) {
      if (server) {
        dispatchToggleDrawer();
      } else {
        // setTimeout required for smooth drawer animation
        setTimeout(() => dispatchToggleDrawer(), 150);
      }
    }

    return {
      type: ChecklistActionTypes.SHOW_SUBTASKS,
      payload: taskId,
    };
  };

export const updateGroup = (
  task: IGroup,
  checked: boolean,
  taskName = '',
  updatedMainTasksMethod = '',
) => ({
  type: ChecklistActionTypes.UPDATE_TASK_START,
  payload: {
    task,
    checked,
    taskName,
    updatedMainTasksMethod,
  },
});

export const updateSubtasks =
  (
    tasks: {
      taskId: string;
      checked: boolean;
      name?: string;
      method: 'passive' | 'active';
    }[],
  ) =>
  ({ dispatch, cordovaTracker }: IDeps) => {
    tasks.forEach(({ checked, method, name, taskId }) => {
      cordovaTracker.track(TrackingEvent.UpdatedSubtasks);
      dispatch(
        updatedSubtaskAnalytics({
          subtaskId: taskId,
          name,
          checked,
          method,
        }),
      );
    });

    return {
      type: ChecklistActionTypes.UPDATE_SUBTASKS,
      payload: { tasks },
    };
  };

export const updateSubtasksSuccess = (payload: { method: 'passive' | 'active' }) => ({
  type: ChecklistActionTypes.UPDATE_SUBTASKS_SUCCESS,
  payload,
});

export const fetchRelatedSuppliers = (supplierSlug: string) => ({
  type: ChecklistActionTypes.FETCH_RELATED_SUPPLIERS,
  payload: { supplierSlug },
});

export const fetchRelatedSuppliersSuccess = (payload: any) => () => {
  const loadedSuppliers = values(payload.results);

  return {
    type: ChecklistActionTypes.FETCH_RELATED_SUPPLIERS_SUCCESS,
    payload: loadedSuppliers,
  };
};

export const initialiseChecklistAnalytics = (initial = true) => ({
  type: initial ? 'INITIALISE_CHECKLIST_ANALYTICS' : 'REINITIALISE_CHECKLIST_ANALYTICS',
});

export const initialiseChecklist = (initial = true) => ({
  type: ChecklistActionTypes.INITIALISE_CHECKLIST,
  payload: { initial },
});

export const reInitialiseChecklist = (initial = true) => ({
  type: ChecklistActionTypes.REINITIALISE_CHECKLIST,
  payload: initial,
});

export const addTask =
  (period: string, name: string, groupId: string | null = null) =>
  () => ({
    type: ChecklistActionTypes.ADD_TASK,
    payload: { groupId, period, name },
  });

export const deleteTaskGroup = (groupId: string) => ({
  type: ChecklistActionTypes.DELETE_TASK_GROUP,
  payload: { groupId },
});

export const triggerDeleteTaskGroupAnalytics = (group: IGroup) => ({
  type: 'TRIGGER_DELETE_TASK_MAIN_ANALYTICS',
  payload: {
    // todo: Fix payload property names and remove duplication
    name: group.name,
    task: group,
  },
});

export const deleteTask = (task: IChecklistGroup) => ({
  type: ChecklistActionTypes.DELETE_TASK,
  payload: { task },
});

export const triggerDeleteTaskAnalytics = (
  task: IChecklistGroup,
  groupName: string,
  taskName: string,
) => ({
  type: 'TRIGGER_DELETE_TASK_SUB_ANALYTICS',
  // todo: Fix payload property names
  payload: { task, taskName: groupName, name: taskName },
});

export const confirmDeleteSubtaskAnalytics = (task: ITask | IGroup) => ({
  type: 'DELETE_TASK_SUB_ANALYTICS',
  payload: { task },
});

export const confirmDeleteMainTaskAnalytics = (task: ITask | IGroup) => ({
  type: 'DELETE_TASK_MAIN_ANALYTICS',
  payload: { task },
});

export const filterChecklist =
  (payload: IChecklistFilter) =>
  ({ dispatch }: IDeps) => {
    dispatch({ type: 'FILTER_CHECKLIST_ANALYTICS', payload });
    return {
      type: ChecklistActionTypes.FILTER_CHECKLIST,
      payload,
    };
  };

export const onEditTaskAssignee =
  (task: IGroup, taskName: string) =>
  ({ dispatch }: IDeps) => {
    const { id: taskId } = task;

    dispatch({
      type: 'ON_EDIT_TASK_ASSIGNEE_ANALYTICS',
      payload: { task, taskId, taskName },
    });

    return {
      type: ChecklistActionTypes.ON_EDIT_TASK_ASSIGNEE,
    };
  };

export const saveTaskAssignee =
  ({ task, name, taskName }: { task: IGroup; name: string; taskName: string }) =>
  ({ dispatch }: IDeps) => {
    const { id } = task;

    dispatch({
      type: 'SAVE_TASK_ASSIGNEE_ANALYTICS',
      payload: { task, taskId: id, taskName, newAssigneeName: name },
    });

    return {
      type: ChecklistActionTypes.SAVE_TASK_ASSIGNEE,
      payload: { taskId: id, assignee: name },
    };
  };

export const onTriggerSocialShareTask =
  (network: string) =>
  ({ getState, dispatch }: IDeps) => {
    const {
      checklist: { tasks, detailsTaskId },
    } = getState();
    const task = detailsTaskId ? tasks[detailsTaskId] : undefined;

    if (task && task.name) {
      dispatch({
        type: 'ON_TRIGGER_SOCIAL_SHARE_TASK_ANALYTICS',
        payload: { task, taskName: task.name, network },
      });
    }

    return {
      type: ChecklistActionTypes.ON_TRIGGER_SOCIAL_SHARE_TASK,
    };
  };

export const onCopyTaskUrlAnalytics =
  (method: string) =>
  ({ getState, dispatch }: IDeps) => {
    const {
      checklist: { tasks, detailsTaskId },
      app: { pathname, device: host },
    } = getState();
    const task = detailsTaskId ? tasks[detailsTaskId] : undefined;
    if (task && task.name) {
      const url = pathname ? `${host}${pathname}` : '';
      dispatch({
        type: 'ON_COPY_TASK_URL_ANALYTICS',
        payload: { task, taskName: task.name, method, url },
      });
    }

    return {
      type: ChecklistActionTypes.ON_COPY_TASK_URL,
    };
  };

export const followedSubtasksLink =
  (task: ITask, taskName: string, url: string) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'FOLLOWED_SUBTASKS_LINK_ANALYTICS',
      payload: { task, taskName, url },
    });

    return {
      type: ChecklistActionTypes.FOLLOWED_SUBTASKS_LINK,
    };
  };

export const usedTabNavigationOnSubtaskViewAnalytics = (
  task: IGroup,
  taskName: string,
  tab: any,
) => ({
  type: 'USED_TAB_NAVIGATION_ON_SUBTASK_VIEW_ANALYTICS',
  payload: {
    task,
    taskName,
    tab,
  },
});

export const addChecklistTaskToggle =
  (value?: boolean) =>
  ({ getState }: IDeps) => {
    const toggle = typeof value === 'boolean' ? value : !getState().checklist.showAddTask;

    return {
      type: ChecklistActionTypes.ADD_CHECKLIST_TASK_TOGGLE,
      payload: toggle,
    };
  };

export const assignTaskToggle =
  (value?: boolean) =>
  ({ getState }: IDeps) => {
    const toggle = typeof value === 'boolean' ? value : !getState().checklist.showAssignTask;

    return {
      type: ChecklistActionTypes.ASSIGN_TASK_TOGGLE,
      payload: toggle,
    };
  };

export const clickedAddMainTaskAnalytics = () => ({
  type: ChecklistActionTypes.CLICKED_ADD_MAIN_TASK_ANALYTICS,
});

export const changeTaskPeriod = (newPeriod: string) => ({
  type: ChecklistActionTypes.TASKS_PERIOD_CHANGE,
  payload: newPeriod,
});

export const changeTaskPeriodSuccess = () => ({
  type: ChecklistActionTypes.TASKS_PERIOD_CHANGE_SUCCESS,
});
