/* eslint-disable */
import type { CountryCodes, firebase } from '../../firebase/firestore';

export interface ISupplier {
  address?: IAddress;
  booked: boolean;
  contacts?: IContacts;
  /**
   * @deprecated - use TalkJS as the only source of truth for conversations
   */
  conversation?: {
    /**
     * Conversation ID
     */
    id: string;
    lastMessageCouple?: IMessage;
    lastMessageSupplier?: IMessage;
  };
  createdAt: firebase.firestore.Timestamp;
  customSupplier: boolean;
  enquired: boolean;
  id: string;
  ignored?: boolean;
  name?: string;
  notes?: string;
  price?: number;
  professional: boolean;
  shortlisted: boolean;
  type:
    | 'beauty'
    | 'cakes'
    | 'catering'
    | 'decoration'
    | 'dress'
    | 'entertainment'
    | 'florist'
    | 'group'
    | 'jewellery'
    | 'marquee'
    | 'menswear'
    | 'music'
    | 'photo'
    | 'planners'
    | 'stationery'
    | 'transport'
    | 'venue'
    | 'video';
  updatedAt?: firebase.firestore.Timestamp;
  visitDate?: string;
}
export interface IAddress {
  adminArea?: string[];
  city?: string;
  country?: CountryCodes;
  name?: string;
  postalCode?: string;
  provider?: {
    id: string;
    type: 'google.places';
  };
  street?: string[];
}
export interface IContacts {
  email?: string;
  emailRecords?: MXRecords;
  phone?: string;
  website?: string;
}
/**
 * Email MX Records
 */
export interface MXRecords {
  /**
   * MX Priority Map
   */
  exchanges?: {
    [k: string]: number;
  };
  /**
   * Canonical Email Providers
   */
  providers?: string[];
}
export interface IMessage {
  automaticOrigin?: AutomaticMessageOrigin;
  date: firebase.firestore.Timestamp;
  /**
   * Message ID
   */
  id: string;
  origin: MessageOrigin;
  type: MessageType;
  /**
   * Message
   */
  value: string;
}

export enum AutomaticMessageOrigin {
  FOLLOW_UP = 'followup',
  BROCHURE = 'brochure',
  ENQUIRY = 'enquiry',
}
export enum MessageOrigin {
  EMAIL = 'email',
  IMPORT = 'import',
  REST = 'rest',
  WEB = 'web',
}
export enum MessageType {
  ATTACHMENT = 'attachment',
  TEXT = 'text',
}
