import { CustomAutocompletePrediction } from '../../types';
import { customPredictions } from './custom-predictions';

export class CustomPredictionService {
  getMatching(
    countryCode: string | undefined,
    normalizedInput: string,
  ): CustomAutocompletePrediction[] {
    const allPredictions = Object.values(customPredictions).flat();
    // No countryCode means global search is enabled. In this case we want to make custom areas global
    const predictions = countryCode ? customPredictions[countryCode] : allPredictions;

    // Filter out undefined values and then apply matching logic
    return (predictions || [])
      .filter((prediction): prediction is CustomAutocompletePrediction => !!prediction) // Ensure all values are defined
      .filter((prediction) => {
        const matchByRule = this.matchByRule(prediction, normalizedInput);
        return matchByRule || this.matchByName(prediction, normalizedInput);
      });
  }

  private matchByRule(prediction: CustomAutocompletePrediction, normalizedInput: string) {
    if (prediction.matchingRule) {
      const rule = prediction.matchingRule;
      const minLengthMatch = normalizedInput.length >= rule.minLength;
      if (rule.keywords?.length > 0) {
        const keywordMatch =
          rule.keywords.filter((keyword) => keyword.startsWith(normalizedInput)).length > 0;
        return keywordMatch && minLengthMatch;
      }
      return minLengthMatch;
    }
    return false;
  }

  private matchByName(prediction: CustomAutocompletePrediction, normalizedInput: string) {
    const area = prediction.area.trim().toLocaleLowerCase();
    if (normalizedInput.includes(' ')) {
      return area.includes(normalizedInput);
    }
    const areaMatch = area.split(' ').filter((part) => part.startsWith(normalizedInput));
    return areaMatch.length > 0;
  }
}
