import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { basicFields } from '@bridebook/toolbox/src/google/maps';
import type { Slug } from '@bridebook/toolbox/src/types';
import { AutocompletePrediction, ILatLongBounds } from 'app-shared/lib/search/types';
import { CustomPredictionService } from 'app-shared/lib/search/utils/custom-autocomplete-predictions/custom-prediction-service';
import { GooglePlacesService } from 'app-shared/lib/search/utils/google-places-service';
import { Locales } from 'lib/i18n/constants';

interface IGetPlaceAutocompleteOptions {
  types: ('geocode' | 'establishment')[];
  bounds: ILatLongBounds;
  countryCode?: CountryCodes;
}

export interface IGetAutocompletePredictionsOptions
  extends Omit<IGetPlaceAutocompleteOptions, 'types'> {
  category: Slug | '';
  types?: IGetPlaceAutocompleteOptions['types'];
}

export class AutocompletePredictionsService {
  constructor(
    private customPredictionService: CustomPredictionService,
    private googlePlacesService: GooglePlacesService,
  ) {}

  getAutocompletePredictions = async (
    input: string,
    { category, countryCode, bounds, types = ['geocode'] }: IGetAutocompletePredictionsOptions,
  ) => {
    const googlePredictions = await this.googlePlacesService.getPredictions(
      input,
      this.getPlaceAutocompleteOptions({
        types,
        bounds,
        countryCode,
      }),
    );
    const predictions = this.getPredictionsWithCustoms(input, countryCode, googlePredictions);

    return {
      category,
      predictions,
      input,
      googlePredictions: googlePredictions.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.place_id]: curr,
        }),
        {},
      ),
    };
  };

  private getPredictionsWithCustoms = (
    input: string,
    countryCode: string | undefined,
    googlePredictions: google.maps.places.AutocompletePrediction[] | null,
  ): AutocompletePrediction[] => {
    const normalizedInput = input.trim().toLocaleLowerCase();
    if (!normalizedInput.length) {
      return [];
    }

    // Add customized predictions
    const customPredictions = this.customPredictionService.getMatching(
      countryCode,
      normalizedInput,
    );
    const googlePredictionsDeduplicated = (googlePredictions || []).filter((prediction) => {
      const exists = customPredictions.find((customPrediction) => {
        const mainTextMatch =
          prediction.structured_formatting.main_text === customPrediction.mainText;
        if (customPrediction.replaceByMainText) {
          return mainTextMatch;
        }
        const secondaryTextMatch =
          prediction.structured_formatting.secondary_text === customPrediction.secondaryText;
        return mainTextMatch && secondaryTextMatch;
      });
      return !exists;
    });
    const finalPredictions = [...customPredictions, ...googlePredictionsDeduplicated];

    if (finalPredictions.length === 0) {
      return [];
    }
    return finalPredictions;
  };

  private getPlaceAutocompleteOptions = ({
    countryCode,
    types,
    bounds = {
      ne: { lat: -90, lon: 180 },
      sw: { lat: 90, lon: 180 },
    },
  }: IGetPlaceAutocompleteOptions): google.maps.places.AutocompleteOptions => {
    const result: google.maps.places.AutocompleteOptions = {
      bounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(bounds.sw.lat, bounds.sw.lon),
        new google.maps.LatLng(bounds.ne.lat, bounds.ne.lon),
      ),
      fields: basicFields,
      types,
    };

    const showOnlyUK = countryCode === CountryCodes.GB;
    const placesCountry = showOnlyUK ? Locales.UK : countryCode;

    if (types.includes('geocode')) {
      result.componentRestrictions = placesCountry ? { country: placesCountry } : undefined;
    }

    return result;
  };
}
