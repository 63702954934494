import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';
import { ISharedTypes as IStyleProps } from './index';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = ({
  height,
  color,
  sidePaddings,
  noSidePaddings,
  bordered,
  background,
}: IStyleProps): IStyles => {
  const sidePadding = noSidePaddings ? 0 : sidePaddings ? `${sidePaddings}px` : '8px';

  return {
    wrapper: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      height: height ? `${height}px` : '15px',
      borderRadius: '100px',
      background,
      backgroundColor: bordered ? undefined : colors[color],
      border: bordered ? `1px solid ${colors[color]}` : undefined,
      color: bordered ? colors[color] : colors.white,
      fontDefaultSemiBold: 12,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
    },
  };
};

export default styles;
