/*
 Supported YouTube URL formats:
 - http://www.youtube.com/watch?v=My2FRPA3Gf8
 - http://youtu.be/My2FRPA3Gf8
 - https://youtube.googleapis.com/v/My2FRPA3Gf8

 Supported Vimeo URL formats:
 - http://vimeo.com/25451551
 - http://player.vimeo.com/video/25451551

 Also supports relative URLs:
 - //player.vimeo.com/video/25451551
 */
export const parseVideo = (url: string): { type: string; id?: string } => {
  const matches = url.match(
    /^(?:https?:)?[/][/](?:(?:m|player|www)[.])?(vimeo[.]com|youtu(?:[.]be|be(?:[.]googleapis)?[.]com))[/](?:(?:embed|v(?:ideo)?)[/]|watch[?]v=|shorts[/])?([-.\w%]+)/,
  );

  if (matches != null) {
    const id = matches.pop();
    const type = matches.pop()?.includes('vimeo') ? 'vimeo' : 'youtube';
    return {
      id,
      type,
    };
  }

  return {
    type: 'unknown',
  };
};
