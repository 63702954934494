import gazetteer from '@bridebook/toolbox/src/gazetteer';

export const getIsCountrySearch = (searchLocation: string) => {
  searchLocation = searchLocation.toLocaleLowerCase();

  return gazetteer
    .getMarkets()
    .some(
      (market) =>
        market.alias?.toLowerCase() === searchLocation ||
        market.getCountryName().toLocaleLowerCase() === searchLocation,
    );
};
