import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { Conversation } from '@bridebook/toolbox/src/inbox/api/talkjs/webhook.types';
import { QueryClient } from '@tanstack/react-query';
import { GetConversationsListResponse } from 'pages/api/inbox/conversations/[weddingId]';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { toMillis } from 'lib/utils';

export const USE_CONVERSATIONS_QUERY_KEY = ['conversations/list'];

const CACHE_TIME = toMillis(1, 'hour');

const queryClient = new QueryClient({});

export const fetchConversationsList = async (weddingId: string) => {
  /* https://bridebook.atlassian.net/browse/LIVE-20899
   * It is still possible that we will try to fetch the conversations without the weddingId (e.g. when page failed to load after 25s)
   */
  if (!weddingId) return;
  await queryClient.fetchQuery(
    USE_CONVERSATIONS_QUERY_KEY,
    async () => {
      const req = await authenticatedFetch<GetConversationsListResponse>(
        ApiEndpoint.inbox.getConversations(weddingId),
      );
      return req.json();
    },
    {
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
    },
  );
};

export const addConversation = (conversationId: string) => {
  queryClient.setQueryData<any>(USE_CONVERSATIONS_QUERY_KEY, (prevList?: Conversation[]) => {
    /* There won't be any conversation list if the user sends enquiries from onboarding.
     In this case we don't want to add new entries. Conversations will be fetched again once the user navigates to page with send brochure CTAs */
    if (!prevList) return;
    if (prevList.length === 0) return [{ id: conversationId }];
    return [...prevList, { id: conversationId }];
  });
};

export const doesConversationIdExistInTalkJs = (conversationId: string) => {
  const converstations = queryClient.getQueryData(USE_CONVERSATIONS_QUERY_KEY);
  if (!Array.isArray(converstations)) return false;

  return converstations?.some((c) => c.id === conversationId);
};
