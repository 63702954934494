import {
  addedSupplierVisitDateAnalytics,
  addedToBookedSuppliers,
  createCustomSupplier,
  createCustomSupplierSuccess,
  editedSupplierVisitDateAnalytics,
  fetchSupplierCollaboration,
  fetchSupplierCollaborationSuccess,
  fetchUserShortlistSuccess,
  fetchVenueRecommendedSuppliersSuccess,
  onRemovedSuppliersFromShortlist,
  onSuppliersShortlistedSuccess,
  removeFromShortlist,
  removedFromRecommendationsAnalytics,
  setCustomSupplierFormField,
  setFromBookedSupplier,
  setShortlistedLocation,
  shortlistBookSupplier,
  showShortlistUserLimitModal,
  toggleConfirmBookingModal,
  toggleCustomSupplierFormModal,
  toggleGoogleSearchModal,
  toggleSupplierAddModal,
  toggleSupplierCollaboration,
  toggleSupplierDetailsDrawer,
  toggledTabNavigationOnShortlistAnalytics,
  unbookAllVenues,
  unbookSupplier,
  updateShortlistedSupplier,
} from './actions';
import { createCustomSupplierError } from './epics-custom-supplier';

export enum ShortlistActionTypes {
  CLEAR_CUSTOM_SUPPLIER_FORM = '[Shortlist] Clear custom supplier form',
  CREATE_CUSTOM_SUPPLIER = '[Shortlist] Create custom supplier',
  CREATE_CUSTOM_SUPPLIER_SUCCESS = '[Shortlist] Create custom supplier success',
  CLEAR_CUSTOM_SUPPLIER_ADDRESS_FORM = '[Shortlist] Clear custom supplier address form',
  USE_VALIDATION_ON_TYPE = '[Shortlist] Use validation on type',
  CUSTOM_SUPPLIER_FORM_SHOW_NEXT_ERROR = '[Shortlist] Custom supplier form show next error',
  CUSTOM_SUPPLIER_FORM_VALID = '[Shortlist] Custom supplier form valid',
  HIDE_CUSTOM_SUPPLIER_FORM_ERROR = '[Shortlist] Hide custom supplier form error',
  FETCH_USER_SHORTLIST_SUCCESS = '[Shortlist] Fetch user shortlist success',
  ON_FB_SHORTLIST_CHILD_CHANGED = '[Shortlist] On fb shortlist child changed',
  ON_REMOVED_SUPPLIERS_FROM_SHORTLIST = '[Shortlist] On removed suppliers from shortlist',
  ON_SUPPLIERS_SHORTLISTED_SUCCESS = '[Shortlist] On suppliers shortlisted success',
  SET_CUSTOM_SUPPLIER_FORM_FIELD = '[Shortlist] Set custom supplier form field',
  SAVE_TO_SHORTLIST = '[Shortlist] Save to shortlist',
  REMOVE_FROM_SHORTLIST = '[Shortlist] Remove from shortlist',
  CREATE_CUSTOM_SUPPLIER_ERROR = '[Shortlist] Create custom supplier error',
  TOGGLE_SUPPLIER_ADD_MODAL = '[Shortlist] Toggle supplier add modal',
  TOGGLE_SUPPLIER_DETAILS_DRAWER = '[Shortlist] Toggle supplier details drawer',
  UPDATE_SHORTLISTED_SUPPLIER = '[Shortlist] Update shortlisted supplier',
  TOGGLE_GOOGLE_SEARCH_MODAL = '[Shortlist] Toggle google search modal',
  TOGGLE_CUSTOM_SUPPLIER_FORM_MODAL = '[Shortlist] Toggle custom supplier form modal',
  ADDED_TO_BOOKED_SUPPLIERS = '[Shortlist] Added to booked suppliers',
  BOOK_SUPPLIER = '[Shortlist] Book a supplier',
  UNBOOK_SUPPLIER = '[Shortlist] Unbook a supplier',
  TOGGLE_SUPPLIER_COLLABORATION = '[Shortlist] Toggle collaboration with supplier',
  FETCH_SUPPLIER_COLLABORATION = '[Shortlist] Fetch supplier collaboration start',
  FETCH_SUPPLIER_COLLABORATION_SUCCESS = '[Shortlist] Fetch supplier collaboration success',
  MARK_SUPPLIER_IGNORED = '[Shortlist] Mark supplier ignored',
  REMOVED_SUPPLIER_FROM_RECOMMENDATIONS_ANALYTICS = '[Analytics] [Shortlist] Removed supplier from recommendations analytics',
  SET_SHORTLISTED_LOCATION = '[Shortlist] Set shortlisted location',
  TRIGGERED_BOOKED_SUPPLIER_POP_UP_ANALYTICS = '[Analytics] [Shortlist] Triggered booked supplier popup',
  TRIGGERED_ADD_SUPPLIER_POP_UP_ANALYTICS = '[Analytics] [Shortlist] Triggered add supplier popup',
  ADDED_SUPPLIER_VISIT_DATE_ANALYTICS = '[Analytics] [Shortlist] Added supplier visit date analytics',
  EDITED_SUPPLIER_VISIT_DATE_ANALYTICS = '[Analytics] [Shortlist] Edited supplier visit date analytics',
  REMOVED_SUPPLIER_VISIT_DATE_ANALYTICS = '[Analytics] [Shortlist] Removed supplier visit date analytics',
  BOOKED_SUPPLIER_ANALYTICS = '[Analytics] [Shortlist] Booked supplier analytics',
  UNBOOK_SUPPLIER_ANALYTICS = '[Analytics] [Shortlist] Unbook supplier analytics',
  SHOW_SHORTLIST_USER_LIMIT_MODAL = '[Shortlist] Show shortlist user limit modal',
  TOGGLE_CONFIRM_BOOKING_MODAL = '[Shortlist] Toggle confirm booking modal',
  UNBOOK_ALL_VENUES = '[Shortlist] Unbook all venues',
  SET_FROM_BOOKED_SUPPLIER = '[Shortlist] Set from booked supplier',
  TOGGLED_TAB_NAVIGATION_ON_SHORTLIST_ANALYTICS = '[Shortlist] Toggled tab navigation on shortlist analytics',
  FETCH_VENUE_RECOMMENDED_SUPPLIERS_SUCCESS = '[Shortlist] Fetch venue recommended suppliers success',
}

export type FetchUserShortlistSuccessAction = ReturnType<typeof fetchUserShortlistSuccess>;

export type OnRemovedSuppliersFromShortlistAction = ReturnType<
  typeof onRemovedSuppliersFromShortlist
>;

export type OnSuppliersShortlistedSuccessAction = ReturnType<typeof onSuppliersShortlistedSuccess>;

export type SetCustomSupplierFormFieldAction = ReturnType<typeof setCustomSupplierFormField>;

export type RemoveFromShortlistAction = ReturnType<ReturnType<typeof removeFromShortlist>>;

export type CreateCustomSupplierErrorAction = ReturnType<
  ReturnType<typeof createCustomSupplierError>
>;

export type ToggleSupplierAddModalAction = ReturnType<typeof toggleSupplierAddModal>;

export type ToggleSupplierDetailsDrawerAction = ReturnType<typeof toggleSupplierDetailsDrawer>;

export type toggleGoogleSearchModalAction = ReturnType<typeof toggleGoogleSearchModal>;

export type toggleCustomSupplierFormModalAction = ReturnType<typeof toggleCustomSupplierFormModal>;

export type ShortlistBookedSupplierAction = ReturnType<ReturnType<typeof shortlistBookSupplier>>;

export type AddedToBookedSuppliersAction = ReturnType<typeof addedToBookedSuppliers>;

export type ShortlistUnbookedSupplierAction = ReturnType<typeof unbookSupplier>;

export type SetShortlistedLocationType = ReturnType<typeof setShortlistedLocation>;

export type AddedSupplierVisitDateAnalyticsAction = ReturnType<
  typeof addedSupplierVisitDateAnalytics
>;

export type EditedSupplierVisitDateAnalyticsAction = ReturnType<
  typeof editedSupplierVisitDateAnalytics
>;

export type RemovedSupplierVisitDateAnalyticsAction = ReturnType<
  typeof removedFromRecommendationsAnalytics
>;

export type ToggleSupplierCollaborationAction = ReturnType<typeof toggleSupplierCollaboration>;

export type FetchSupplierCollaborationAction = ReturnType<typeof fetchSupplierCollaboration>;

export type FetchSupplierCollaborationSuccessAction = ReturnType<
  typeof fetchSupplierCollaborationSuccess
>;

export type UpdateShortlistedSupplierAction = ReturnType<typeof updateShortlistedSupplier>;

export type CreateCustomSupplierSuccessAction = ReturnType<
  ReturnType<typeof createCustomSupplierSuccess>
>;

export type ShowShortlistUserLimitModalAction = ReturnType<typeof showShortlistUserLimitModal>;

export type CreateCustomSupplierAction = ReturnType<typeof createCustomSupplier>;

export type ToggleConfirmBookingModalAction = ReturnType<typeof toggleConfirmBookingModal>;

export type SetFromBookedSupplierAction = ReturnType<typeof setFromBookedSupplier>;

export type UnbookAllVenuesAction = ReturnType<ReturnType<typeof unbookAllVenues>>;

export type ToggledTabNavigationOnShortlistAnalyticsAction = ReturnType<
  typeof toggledTabNavigationOnShortlistAnalytics
>;

export type FetchVenueRecommendedSuppliersSuccessAction = ReturnType<
  ReturnType<typeof fetchVenueRecommendedSuppliersSuccess>
>;
