import { getTestVariant } from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-22479_RandomiseEnquiryMessage';

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_RandomiseEnquiryMessage = (state: IApplicationState) =>
  getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_RandomiseEnquiryMessage = () => {
  useABTestIntegration({
    testId,
  });
};
