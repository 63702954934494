import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IUISupplier } from '@bridebook/toolbox/src/types';

// Percentage of user budget within which should be venue targeting budget
const BUDGET_RANGE_LOWER = 0.5; // Venue targeting min. 50% lower than budget
const BUDGET_RANGE_UPPER = 1.05; // Venue targeting max. 5% higher than budget
// Minimum user budget
const MIN_BUDGET = 1000;
// Maximum user budget
const MAX_BUDGET = 99000;

export const isBudgetMatch = (
  budget: number | null | undefined,
  supplier: IUISupplier | ISupplier | null = {} as ISupplier,
) => {
  const targetingPriceMinBudget = Array.isArray((supplier as ISupplier).type)
    ? (supplier as ISupplier).targeting?.budget?.[0]
    : (supplier as IUISupplier).coupleBudgetMin;
  const supplierType = Array.isArray((supplier as ISupplier).type)
    ? (supplier as ISupplier).type?.[0]
    : (supplier as IUISupplier).type;
  // Budget match is a feature for venues only
  if (!budget || supplierType !== 'venue' || !targetingPriceMinBudget) {
    return false;
  }

  // Budget match logic from LIVE-9688
  return (
    budget >= MIN_BUDGET &&
    budget <= MAX_BUDGET &&
    targetingPriceMinBudget >= budget * BUDGET_RANGE_LOWER &&
    targetingPriceMinBudget <= budget * BUDGET_RANGE_UPPER
  );
};
