import {
  addChecklistTaskToggle,
  assignTaskToggle,
  changeTaskPeriod,
  deleteTask,
  deleteTaskGroup,
  fetchRelatedSuppliersSuccess,
  fetchTasksInitialSuccess,
  fetchUserChecklistSuccess,
  filterChecklist,
  reInitialiseChecklist,
  saveTaskAssignee,
  showSubtasks,
  updateSubtasks,
  updateSubtasksSuccess,
  updateTaskSuccess,
  updatedChecklistTotals,
} from 'lib/checklist/actions';

export enum ChecklistActionTypes {
  // ADD_SUBTASK = '[Checklist] Add subtask',
  ADD_CHECKLIST_TASK_TOGGLE = '[Checklist] Add checklist task toggle',
  ADD_TASK = '[Checklist] Add task',
  ASSIGN_TASK_TOGGLE = '[Checklist] Assign task toggle',
  CLICKED_ADD_MAIN_TASK_ANALYTICS = '[Analytics] [Checklist] Clicked add main task',
  CLOSE_SUBTASKS = '[Checklist] Close subtasks',
  DELETE_TASK = '[Checklist] Delete task',
  DELETE_TASK_GROUP = '[Checklist] Delete task group',
  FETCH_GROUPS_SUCCESS = '[Groups] Fetch success',
  FETCH_RELATED_SUPPLIERS = '[Checklist] Fetch related suppliers',
  FETCH_RELATED_SUPPLIERS_SUCCESS = '[Checklist] Fetch related suppliers success',
  FETCH_TASKS_INITIAL = '[Checklist] Fetch tasks initial',
  FETCH_TASKS_INITIAL_SUCCESS = '[Checklist] Fetch tasks initial success',
  FETCH_USER_CHECKLIST_SUCCESS = '[Checklist] Fetch user checklist success',
  FILTER_CHECKLIST = '[Checklist] Filter checklist',
  FOLLOWED_SUBTASKS_LINK = '[Checklist] Followed subtasks link',
  INITIALISE_CHECKLIST = '[Checklist] Initialise checklist',
  INIT_LISTENER = '[Checklist] Init listener',
  ON_COPY_TASK_URL = '[Checklist] On copy task url',
  ON_EDIT_TASK_ASSIGNEE = '[Checklist] On edit task assignee',
  ON_TRIGGER_SOCIAL_SHARE_TASK = '[Checklist] On trigger social share task',
  REINITIALISE_CHECKLIST = '[Checklist] Reinitialise checklist',
  SAVE_TASK_ASSIGNEE = '[Checklist] Save task assignee',
  SAVE_TASK_ASSIGNEE_SUCCESS = '[Checklist] Save task assignee success',
  SHOW_SUBTASKS = '[Checklist] Show subtasks',
  STOP_LISTENER = '[Checklist] Stop listener',
  TASKS_PERIOD_CHANGE = '[Checklist] Change task period',
  TASKS_PERIOD_CHANGE_SUCCESS = '[Checklist] Change task period success',
  UPDATE_SUBTASKS = '[Checklist] Update subtasks',
  UPDATE_SUBTASKS_SUCCESS = '[Checklist] Update subtasks success',
  UPDATE_TASK_START = '[Checklist] Update task start',
  UPDATE_TASK_SUCCESS = '[Checklist] Update task success',
  UPDATED_CHECKLIST_TOTALS = 'UPDATED_CHECKLIST_TOTALS',
  FETCH_VENUE_RECOMMENDED_SUPPLIERS_SUCCESS = '[Checklist] Fetch venue recommended suppliers success',
}

export type IShowSubtasksAction = ReturnType<ReturnType<typeof showSubtasks>>;

export type IFetchTasksInitialSuccessAction = ReturnType<typeof fetchTasksInitialSuccess>;

export type IFetchUserChecklistSuccessAction = ReturnType<typeof fetchUserChecklistSuccess>;

export type IFilterChecklistAction = ReturnType<ReturnType<typeof filterChecklist>>;

export type ISaveTaskAssigneeAction = ReturnType<ReturnType<typeof saveTaskAssignee>>;

export type IFetchRelatedSuppliersSuccessAction = ReturnType<
  ReturnType<typeof fetchRelatedSuppliersSuccess>
>;

export type IAddChecklistTaskToggleAction = ReturnType<ReturnType<typeof addChecklistTaskToggle>>;

export type IAssignTaskToggleAction = ReturnType<ReturnType<typeof assignTaskToggle>>;

export type IReInitialiseChecklistAction = ReturnType<typeof reInitialiseChecklist>;

export type IDeleteTaskAction = ReturnType<typeof deleteTask>;

export type IDeleteTaskGroupAction = ReturnType<typeof deleteTaskGroup>;

export type IChangeTaskPeriodAction = ReturnType<typeof changeTaskPeriod>;

export type IUpdateSubtaskAction = ReturnType<ReturnType<typeof updateSubtasks>>;

export type IUpdateSubtasksSuccessAction = ReturnType<typeof updateSubtasksSuccess>;

export type IUpdateTaskSuccessAction = ReturnType<typeof updateTaskSuccess>;

export type IUpdatedChecklistTotal = ReturnType<typeof updatedChecklistTotals>;
