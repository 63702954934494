import { useMemo } from 'react';
import { getTestVariant } from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-22546_RequestABrochureCTAInFR';

export const countriesWithRequestABrochureCTAinFRTest = [CountryCodes.FR];

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_RequestABrochureCTAinFR = (state: IApplicationState) =>
  getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_RequestABrochureCTAinFR = () => {
  const { country } = useMarket();
  const triggerCondition = useMemo(
    () => countriesWithRequestABrochureCTAinFRTest.includes(country),
    [country],
  );

  useABTestIntegration({ testId, triggerCondition });
};
