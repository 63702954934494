import { Validation } from '@bridebook/toolbox/src';
import { ValidationMessages } from '@bridebook/toolbox/src/validation/validation';
import { Locales } from 'lib/i18n/constants';
import { getI18n } from 'lib/i18n/getI18n';

const validate = <T extends object>(json: T) => {
  const i18n = getI18n();
  const validationMessages: ValidationMessages = {
    requiredMessage: (prop: string) => {
      if (prop === 'email') {
        return i18n.t('common:auth.input.email.errorTxt');
      }

      if (prop === 'password') {
        return i18n.t('common:auth.input.password.errorTxt');
      }

      if (prop === 'phone') {
        return i18n.t('common:auth.input.phone.errorTxt');
      }

      if (prop === 'type') {
        return i18n.t('common:auth.input.type.errorTxt');
      }

      if (prop === 'area') {
        return i18n.t('common:auth.input.area.errorTxt');
      }

      if (i18n.language === Locales.UK) {
        return `Please fill out ${prop} field.`;
      }

      return i18n.t('common:error.fillOutMissingFields');
    },
    invalidEmail: i18n.t('common:auth.input.email.format.errorTxt'),
    minPasswordLength: (minLength: number) =>
      i18n.t('common:validation.invalid.password', { minLength }),
    invalidUrl: i18n.t('common:validation.invalid.urlNotValid'),
    invalidUKPhone: i18n.t('common:validation.invalid.phoneUk'),
    invalidPhone: i18n.t('common:validation.invalid.phone'),
    invalidUrlChars: i18n.t('common:validation.invalid.urlValidCharacters'),
    invalidUKPostcode: i18n.t('common:validation.invalid.postcodeUk'),
    requiredVideoUrl: i18n.t('common:validation.invalid.youtubeVimeoUrl'),
    requiredPositiveNumber: i18n.t('common:validation.invalid.positiveInt'),
    equals: i18n.t('common:validation.invalid.equals'),
  };

  return new Validation<T>(json, validationMessages);
};

export default validate;
