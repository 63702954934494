import { coerce, lt, valid } from 'semver';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

export const isCordovaPluginAvailable = (name: string, native: boolean = true): boolean => {
  /** Check if we are running on a native platform */
  if (native && Capacitor.isNativePlatform() === false) {
    return false;
  }

  /** Check if the plugin is loaded */
  return Capacitor.isPluginAvailable(name);
};

/**
 * Since there is no easy way to query the actual version
 * of Capacitor or any of the plugins from the native side
 * we need to workaround this a bit.
 *
 * We therefore use the app version as an nominator.
 * Version 2.2.0 will be the release which bundles
 * Capacitor V4.
 */
export const isCapacitorV3 = async (): Promise<boolean> => {
  if (!isCordovaPluginAvailable('App')) {
    return false;
  }

  const { version } = await App.getInfo();

  /**
   * Our devel versions can have strings appended,
   * therefore we need to clean the version string.
   **/
  const currVersion = valid(coerce(version));

  if (!currVersion) {
    throw new Error('Unable to determine app version string');
  }

  return lt(currVersion, '2.2.0');
};
