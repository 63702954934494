import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconTickBold = ({ id, color, width = 11, style, ...restProps }: IProps) => {
  const viewBoxWidth = 11;
  const viewBoxHeight = 8;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M11 1.6c0-.2-.1-.3-.2-.5l-1-.9c-.3-.3-.7-.3-1 0L4.2 4.6l-2.1-2c-.3-.3-.7-.3-1 0l-1 .9c0 .2-.1.3-.1.5s.1.3.2.5l3.5 3.4c.3.3.7.3 1 0L10.8 2c.1-.1.2-.3.2-.4z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconTickBold;
