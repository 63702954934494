import { add, filter, map, propEq, reduce, sum, values } from 'ramda';
import * as R from 'remeda';
import { ICost } from '@bridebook/models/source/models/Weddings/Costs.types';
import { WeekDayUI } from '@bridebook/toolbox/src/datepicker/types';
import { IWeddingSupplier } from '@bridebook/toolbox/src/types';
import { BudgetGuestsEstimate, BudgetTimeOfYear, BudgetWeekDay } from 'lib/budget/constants';
import { IBudgetInitForm } from 'lib/budget/types';

/**
 * Returns total value of booked suppliers in a category of a given slug
 *
 * @param shortlist
 * @param slug
 */
export const getBookedTotalInCategory = (shortlist: IWeddingSupplier[], slug: string) => {
  return shortlist
    .filter((item) => item.type === slug && item.booked)
    .reduce((acc, el) => {
      acc += Number(el.price);
      return acc;
    }, 0);
};

/**
 * Returns a cost with a given supplier type from an array of costs
 *
 * @param costs
 * @param type
 */
export const findCostByType = (costs: ICost[], type: string) =>
  costs.find((cost) => cost.supplierType === type);

export const sumUp = (list: number[]): number =>
  // @ts-ignore FIXME
  Number(Number(reduce(add, 0, values(list))).toFixed(2));

export const getAvgQuote = (scrapbook: IWeddingSupplier[], slug?: string) => {
  const allPrices = map<IWeddingSupplier, number | undefined>((obj) => Number(obj.price))(
    filter(propEq('type', slug))(scrapbook),
  );
  const prices = filter(Boolean)(allPrices) as number[];
  const avg = Number(Number(Number(sumUp(prices)) / prices.length).toFixed(2)) || 0;
  return isNaN(avg) ? 0 : avg;
};

export const getGuestsValue = (count: number) => {
  if (count === 0) {
    return BudgetGuestsEstimate.undecided;
  } else if (count < 50) {
    return BudgetGuestsEstimate['<50'];
  } else if (count < 100) {
    return BudgetGuestsEstimate['50-99'];
  } else if (count < 150) {
    return BudgetGuestsEstimate['100-149'];
  } else if (count < 250) {
    return BudgetGuestsEstimate['150-250'];
  } else {
    return BudgetGuestsEstimate['>250'];
  }
};

export const mapWeekDayToBudgetWeekDay = (weekDay: WeekDayUI) => {
  switch (weekDay) {
    case 'Friday':
      return BudgetWeekDay.friday;
    case 'Saturday':
      return BudgetWeekDay.saturday;
    case 'Sunday':
      return BudgetWeekDay.sunday;
    case 'Mon-Thurs':
      return BudgetWeekDay.midweek;
    default:
      return '';
  }
};

export const mapSeasonToTimeOfYear = (season: string) => {
  switch (season) {
    case 'summer':
      return BudgetTimeOfYear.peak;
    case 'spring':
    case 'autumn':
    case 'winter':
      return BudgetTimeOfYear.offpeak;
    default:
      return BudgetTimeOfYear.undecided;
  }
};

export const getTimeOfWeekValue = (weekday: number) => {
  if (weekday === 0) {
    return BudgetWeekDay.sunday;
  } else if (weekday < 5) {
    return BudgetWeekDay.midweek;
  } else if (weekday === 5) {
    return BudgetWeekDay.friday;
  } else if (weekday === 6) {
    return BudgetWeekDay.saturday;
  }
  return BudgetWeekDay.undecided;
};

export const getYearValue = (year: number) => {
  const maxYear = new Date().getFullYear() + 3;
  let yearValue;
  if (year >= maxYear) {
    yearValue = maxYear;
  } else {
    yearValue = year;
  }
  return yearValue.toString();
};

export const getTimeOfYearValue = (month: number) => {
  if (month <= 9 && month >= 5) {
    return BudgetTimeOfYear.peak;
  } else if (month === 12) {
    return BudgetTimeOfYear.christmas;
  } else {
    return BudgetTimeOfYear.offpeak;
  }
};

export const validateInitForm = (form: IBudgetInitForm) => {
  const budget = form.fields.budget;
  if (!budget || budget < 1000) {
    throw Error();
  }
  return true;
};

/**
 * Sums up actual and estimate values of provided costs
 * estimate - Sum of all budget item’s booked values + sum of all budget item’s (without booked values) estimate values [LIVE-9779]
 * @param costs
 */
export const getTotals = (costs: ICost[]) => ({
  actual: sum(R.map(costs, R.prop('actual'))),
  estimate: sum(R.map(costs, R.prop('estimate'))),
});
