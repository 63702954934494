import { Browser } from '@capacitor/browser';
import { getI18n } from 'lib/i18n/getI18n';

type InjectedScriptParams = {
  callback: () => void;
  script: string;
  style: string;
};

const openInBrowser = async (
  url: string | null | undefined,
  target = '_blank',
  injectedScriptParams?: InjectedScriptParams,
) => {
  if (!url) {
    console.error('Cannot open undefined URL.');
    return;
  }

  const isHTTPS = /^https:[/][/]/.test(url);

  /**
   * Script injection is only supported for HTTPS URLs due to CSP.
   */
  if (injectedScriptParams && isHTTPS) {
    const i18n = getI18n();
    const { InAppBrowser } = require('@awesome-cordova-plugins/in-app-browser');

    const browser = InAppBrowser.create(url, '_blank', {
      location: 'no',
      closebuttoncaption: i18n.t('common:close'),
      disallowoverscroll: 'yes',
      hidenavigationbuttons: 'yes',
      fullscreen: 'yes',
    });

    const onLoaded = () => {
      browser.executeScript({
        code: injectedScriptParams.script,
      });

      browser.insertCSS({
        code: injectedScriptParams.style,
      });

      browser.show();
    };

    browser.on('loadstop').subscribe(() => {
      onLoaded();
    });

    browser.on('message').subscribe(() => {
      browser.close();
      injectedScriptParams.callback();
    });
  } else {
    Browser.open({ url, windowName: target });
  }
};

export default openInBrowser;
