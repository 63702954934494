import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';

const pattern =
  /^([-+])?P(?:(?:(-?[0-9]+)Y)?(?:(-?[0-9]+)M)?(?:(-?[0-9]+)W)?(?:(-?[0-9]+)D)?(?:T(?:(-?[0-9]+)H)?(?:(-?[0-9]+)M)?(?:(-?[0-9]+)S)?)?)$/;

export default function applyIsoDuration(date: Date, duration: string) {
  let result = duration.match(pattern);

  if (result == null) {
    throw new Error(`Invalid ISO 8601 duration. ${duration}`);
  }

  let invert = false;

  if (result[1] !== undefined) {
    invert = result[1] === '-';
  }

  if (result[2] !== undefined) {
    let absolute = Math.abs(parseInt(result[2], 10));
    let negative = result[2].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subYears(date, absolute) : addYears(date, absolute);
  }

  if (result[3] !== undefined) {
    let absolute = Math.abs(parseInt(result[3], 10));
    let negative = result[3].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subMonths(date, absolute) : addMonths(date, absolute);
  }

  if (result[4] !== undefined) {
    let absolute = Math.abs(parseInt(result[4], 10));
    let negative = result[4].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subWeeks(date, absolute) : addWeeks(date, absolute);
  }

  if (result[5] !== undefined) {
    let absolute = Math.abs(parseInt(result[5], 10));
    let negative = result[5].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subDays(date, absolute) : addDays(date, absolute);
  }

  if (result[6] !== undefined) {
    let absolute = Math.abs(parseInt(result[6], 10));
    let negative = result[6].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subHours(date, absolute) : addHours(date, absolute);
  }

  if (result[7] !== undefined) {
    let absolute = Math.abs(parseInt(result[7], 10));
    let negative = result[7].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subMinutes(date, absolute) : addMinutes(date, absolute);
  }

  if (result[8] !== undefined) {
    let absolute = Math.abs(parseInt(result[8], 10));
    let negative = result[8].startsWith('-');

    if (invert === true) {
      negative = !negative;
    }

    date = negative ? subSeconds(date, absolute) : addSeconds(date, absolute);
  }

  return date;
}
