import Talk from 'talkjs';
import type { Conversation, UserOptions } from 'talkjs/types/talk.types';
import { Message } from '../inbox/api/talkjs/webhook.types';

export enum TalkJsRoles {
  Admin = 'admin',
  Couple_UK = 'couple',
  Couple_DE = 'couple_DE',
  Couple_FR = 'couple_FR',
  Couple_IE = 'couple_IE',
  Couple_Global = 'couple_global',
  Supplier_UK = 'supplier',
  Supplier_DE = 'supplier_DE',
  Supplier_FR = 'supplier_FR',
  Supplier_IE = 'supplier_IE',
  Supplier_Global = 'supplier_global',
}

export interface ChatUserOptions extends UserOptions {
  id: string;
  role: TalkJsRoles;
}

export interface CoupleChatUserOptions extends ChatUserOptions {
  custom: {
    userId: string;
  };
}

export interface SupplierChatUserOptions extends ChatUserOptions {
  custom: {
    userId: string;
    publicId: string;
    type: string;
    city: string;
    adminArea0: string;
    adminArea1: string;
    country: string;
    photoUrl: string;
    seoUrlSlug: string;
  };
}

export interface TalkJsUserWithSignature {
  user: Talk.User;
  signature: string;
}

/**
 * Object used to create conversation via REST API
 * @see: https://talkjs.com/dashboard/tI9iW5Fu/docs/Reference/REST_API/Conversations.html
 */
export interface ConversationOptions extends Omit<Conversation, 'participants'> {
  participants: string[];
  custom: {
    supplierWeddingId: string;
    /* @deprecated - only in old conversations, enquiryId was supposed to be supplierWeddingId */
    enquiryId?: string;
    // it would be more natural to use a boolean here, but talkjs only accepts string or null
    // for custom properties: https://talkjs.com/docs/Reference/Concepts/Conversations/#custom
    archivedByCouple?: 'true' | null;
    repliedByCouple?: string; // Timestamp of the last reply by couple (excluding initial message)
    repliedBySupplier?: string; // Timestamp of the last reply by supplier
  };
}

/**
 * @see https://talkjs.com/docs/Reference/REST_API/Messages/#sending-on-behalf-of-a-user
 */
export interface CustomMessageBody {
  text?: Message['text'];
  type?: Message['type'];
  sender?: string;
  attachmentToken?: string;
  referencedMessageId?: string;
  custom?: Message['custom'];
  idempotencyKey?: string;
}

export type ConversationListFilters = {
  archivedByCouple?: boolean;
  startingAfter?: string;
  limit: number;
};
