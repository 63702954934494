import {
  confirmSupplierInterest,
  confirmVenue,
  denySupplierInterest,
  saveSupplierIdToConfirm,
  saveVenueEstimate,
  triggerBookingConfirmationModal,
  venueBookedToggle,
  venueConfirmChangeSlide,
  venueConfirmSaveVenueInState,
} from './actions';

export enum VenueConfirmActionTypes {
  VENUE_BOOKED_TOGGLE = '[VenueConfirm] Venue booked toggle',
  SAVE_VENUE_TO_BOOK = '[VenueConfirm] Save venue to book',
  CHANGE_SLIDE = '[VenueConfirm] Change slide',
  SAVE_VENUE_PRICE = '[VenueConfirm] Save venue price',
  CONFIRM_VENUE = '[VenueConfirm] Confirm venue',
  TRIGGER_SUPPLIER_CONFIRMATION = '[VenueConfirm] Trigger supplier interest confirmation',
  SAVE_SUPPLIER_ID_TO_CONFIRM = '[VenueConfirm] Save supplier id',
  CONFIRM_SUPPLIER_INTEREST = '[VenueConfirm] Confirm supplier interest',
  DENY_SUPPLIER_INTEREST = '[VenueConfirm] Deny supplier interest',
  SAVE_WEDDING_YEAR = '[VenueConfirm] Save wedding year',
  SAVE_WEDDING_MONTH = '[VenueConfirm] Save wedding month',
  SAVE_WEDDING_DAY = '[VenueConfirm] Save wedding day',
  CLOSE_CONFIRMATION_MODAL = '[VenueConfirm] Close confirmation modal',
  TRIGGER_BOOKING_CONFIRMATION_MODAL = '[VenueConfirm] Trigger booking confirmation modal',
  CLOSE_BOOKING_CONFIRMATION_MODAL = '[VenueConfirm] Close booking confirmation modal',
}

export type VenueBookedToggleAction = ReturnType<typeof venueBookedToggle>;

export type VenueConfirmSaveVenueInStateAction = ReturnType<typeof venueConfirmSaveVenueInState>;

export type VenueConfirmChangeSlideAction = ReturnType<typeof venueConfirmChangeSlide>;

export type SaveVenueEstimateAction = ReturnType<typeof saveVenueEstimate>;

export type ConfirmVenueAction = ReturnType<ReturnType<typeof confirmVenue>>;

export type SaveSupplierIdToConfirmAction = ReturnType<typeof saveSupplierIdToConfirm>;

export type ConfirmSupplierInterestAction = ReturnType<typeof confirmSupplierInterest>;

export type DenySupplierInterestAction = ReturnType<typeof denySupplierInterest>;

export type TriggerBookingConfirmationModalAction = ReturnType<
  typeof triggerBookingConfirmationModal
>;
