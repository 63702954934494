const getDefaultEnquiryCheckboxValue = ({ contextName }: { contextName: string }): string => {
  switch (contextName) {
    case 'supplierProfile_preferredSuppliers':
    case 'supplierProfile_awards':
    case 'supplierProfile_details':
    case 'supplierProfile_topButtonDownloadable':
    case 'supplierProfile_sideBar':
    case 'supplierProfile_bottomButton':
    case 'supplierProfile_topButton':
    case 'search_button':
      return 'brochure';
    case 'linkedSupplier_weddingConfirmationPopup':
      return 'availability';
    case 'supplierProfile_getAQuote':
    case 'supplierProfile_pricingSection':
      return 'quote';
    case 'supplierProfile_actionsSection_showround':
    case 'supplierProfile_showround_v1':
    case 'supplierProfile_showround_v2':
      return 'showround';
    case 'supplierProfile_video_tour':
      return 'videotour';
    default:
      return '';
  }
};

export default getDefaultEnquiryCheckboxValue;
